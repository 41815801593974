import React, { useEffect, useMemo, useState } from "react";
import {
  useGetFoodTypeByIdQuery,
  useGetFoodTypesSelectBoxQuery,
} from "../../../../../store/apis/FoodTypesApis";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";
import {
  useAddResidentFoodTypeMutation,
  useResidentFoodTypesByIdQuery,
  useResidentFoodTypesQuery,
  useUpdateResidentFoodTypeMutation,
} from "../../../../../store/apis/ResidentApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { Box, Grid } from "@mui/material";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function AddResidentDislike() {
  const [searchParams] = useSearchParams();
  const [hasChange, setHasChange] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();

  const id = searchParams.get("dislikeId");
  const residentId = searchParams.get("id");

  const isEdit = !!id;

  const {
    data: currentFoodType,
    isFetching: loadingCurrent,
    isSuccess: successCurrent,
  } = useResidentFoodTypesByIdQuery(id, { skip: !id });

  const isEditLoading = loadingCurrent && isEdit;

  const [availableMeals, setAvailableMeals] = useState({
    breakfast: false,
    lunch: false,
    supper: false,
  });

  const [addFoodType, { isLoading: addLoading, isSuccess: addSuccess }] =
    useAddResidentFoodTypeMutation();
  const [
    updateFoodType,
    { isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateResidentFoodTypeMutation();

  const isSubmitting = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors, dirtyFields },
  } = methods;

  const { data: selectedFoodType } = useGetFoodTypeByIdQuery(
    watch("foodTypeId"),
    {
      skip: !watch("foodTypeId"),
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (selectedFoodType && !currentFoodType) {
      setAvailableMeals({
        breakfast: selectedFoodType?.availableBreakfast,
        lunch: selectedFoodType?.availableLunch,
        supper: selectedFoodType?.availableSupper,
      });
    } else if (
      currentFoodType &&
      currentFoodType?.foodTypeId === selectedFoodType?.id
    ) {
      setAvailableMeals({
        breakfast: currentFoodType?.forBreakfast,
        lunch: currentFoodType?.forLunch,
        supper: currentFoodType?.forSupper,
      });
    } else if (
      currentFoodType &&
      currentFoodType?.foodTypeId !== selectedFoodType?.id
    ) {
      setAvailableMeals({
        breakfast: selectedFoodType?.availableBreakfast,
        lunch: selectedFoodType?.availableLunch,
        supper: selectedFoodType?.availableSupper,
      });
    }
  }, [selectedFoodType, currentFoodType]);

  useEffect(() => {
    if (successCurrent && !loadingCurrent) {
      reset(currentFoodType);
      setInitialValues(currentFoodType);
    }
  }, [successCurrent, loadingCurrent]);

  function onSubmit(data) {
    setHasChange(false);
    data["residentId"] = residentId;
    data["isDislike"] = true;
    data["isLike"] = false;
    data["forBreakfast"] = availableMeals?.breakfast;
    data["forLunch"] = availableMeals?.lunch;
    data["forSupper"] = availableMeals?.supper;

    if (data["foodTypeId"] !== -1) {
      data["description"] = "";
    }

    if (isEdit) {
      data["id"] = id;
      updateFoodType(data);
      return;
    }
    addFoodType(data);
  }

  if (!isSubmitting && isSuccess) {
    navigate(-1);
  }

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBlockerModal
            initialValues={initialValues}
            watch={watch}
            getValues={getValues}
            hasChange={hasChange}
            setHasChange={setHasChange}
            setInitialValues={setInitialValues}
            dirtyFields={dirtyFields}
          />
          <Box sx={{ padding: "5px", marginTop: "10px" }}>
            <BackButton To={-1} />
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "10px",
              }}>
              <Grid item md={6}>
                <DislikeSelectbox
                  residentId={residentId}
                  control={control}
                  errors={errors}
                  isEdit={isEdit}
                />
              </Grid>
              <Grid item md={6} />
              {watch("foodTypeId") === -1 && (
                <>
                  <Grid item md={6}>
                    <BaseInput
                      name="description"
                      id="description"
                      label="Description"
                      control={control}
                      validationProps={{
                        required:
                          "Description field is required when selecting Other",
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item md={6} />
                </>
              )}
              <Grid item md={6}>
                <BaseCheckBox
                  name="forBreakfast"
                  id="forBreakfast"
                  label="For Breakfast"
                  control={control}
                  errors={errors}
                  checked={availableMeals?.breakfast}
                  onClick={() =>
                    setAvailableMeals({
                      ...availableMeals,
                      breakfast: !availableMeals.breakfast,
                    })
                  }
                />
              </Grid>
              <Grid item md={6} />
              <Grid item md={6}>
                <BaseCheckBox
                  name="forLunch"
                  id="forLunch"
                  label="For Lunch"
                  control={control}
                  errors={errors}
                  checked={availableMeals?.lunch}
                  onClick={() =>
                    setAvailableMeals({
                      ...availableMeals,
                      lunch: !availableMeals.lunch,
                    })
                  }
                />
              </Grid>
              <Grid item md={6} />
              <Grid item md={6}>
                <BaseCheckBox
                  name="forSupper"
                  id="forSupper"
                  label="For Dinner"
                  control={control}
                  errors={errors}
                  checked={availableMeals?.supper}
                  onClick={() =>
                    setAvailableMeals({
                      ...availableMeals,
                      supper: !availableMeals.supper,
                    })
                  }
                />
              </Grid>
              <Grid item md={6} />
              <Grid item md={6}>
                <BaseSubmitButton
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

function DislikeSelectbox({ control, errors, residentId, isEdit }) {
  const [debouncedValue, setDebouncedValue] = useState("");
  const { data: foodTypes, isLoading } =
    useGetFoodTypesSelectBoxQuery(debouncedValue);
  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedValue(query);
      }, 400),
    []
  );

  const { data: residentDislikes, isLoading: dislikeLoading } =
    useResidentFoodTypesQuery(
      { id: residentId, like: false },
      { skip: !residentId || isEdit }
    );

  const foodTypeData = useMemo(() => {
    return (
      foodTypes?.filter(
        (item) =>
          item.isDislike &&
          !residentDislikes?.some(
            (x) => x.foodTypeId == item.id && !x.foodTypeName.includes("Other")
          )
      ) || []
    );
  }, [residentDislikes, foodTypes]);

  return (
    <BaseSelect
      name="foodTypeId"
      id="foodTypeId"
      label="Dislike/Intolerance"
      validationProps={{
        required: REQUIRED_ERROR("Dislike/Intolerance"),
      }}
      control={control}
      errors={errors}
      options={foodTypeData}
      loading={isLoading || dislikeLoading}
      handleSearch={handleSearch}
    />
  );
}
