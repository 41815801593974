import React from "react";

import GenericFieldForm from "./GenericFieldForm";
import Section from "./Section";
import { SECTION_GROUP_DEFAULTS, SECTION_GROUP_FORM } from "./forms.constants";

export default function SectionGroup() {
  return (
    <GenericFieldForm
      name={"sectionGroups"}
      appendObjectExample={SECTION_GROUP_DEFAULTS}
      nameOfObject={"Section Groups"}
      fieldPropName={`sectionGroups`}
      fieldList={SECTION_GROUP_FORM}
      ChildComponent={Section}
      isSortOrder={true}></GenericFieldForm>
  );
}
