import { Box, Grid2, Typography, useTheme } from "@mui/material";
import React from "react";
import FieldGroup from "./FieldGroup";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";

export default function Section({ section }) {
  const theme = useTheme();

  if (section?.name !== "-") {
    return (
      <Grid2 size={section.size}>
        <Box width={"100%"}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              p: "16px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              borderRadius: 1,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}>
            <Typography
              variant={"h6"}
              sx={{
                fontWeight: "600",
                color: theme.palette.primary.contrastText,
              }}
              color={theme.palette.primary.contrastText}>
              {section?.name}
            </Typography>
            <Box>
              {section?.hasIsApplicableCheck ? (
                <BaseCheckBox
                  size={"small"}
                  name={`IsApplicable-${section.id}`}
                  label={"Is Applicable"}
                />
              ) : null}
              {section?.hasSelectAllCompletedCheck ? (
                <BaseCheckBox
                  size={"small"}
                  name={`allCompleted-${section.id}`}
                  label={"All Completed"}
                />
              ) : null}
            </Box>
          </Box>

          <Box
            p={1}
            borderRadius={1}
            borderColor={"grey"}
            border={"2px solid"}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            borderTop={"unset"}>
            {section?.fieldGroups?.map((fieldGroup) => (
              <FieldGroup key={fieldGroup.id} fieldGroup={fieldGroup} />
            ))}
          </Box>
        </Box>
      </Grid2>
    );
  }

  return (
    <Grid2 size={section.size}>
      {section?.fieldGroups?.map((fieldGroup) => (
        <FieldGroup key={fieldGroup.id} fieldGroup={fieldGroup} />
      ))}
    </Grid2>
  );
}
