import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import BaseRadio from "../../../../shared/baseForm/BaseRadio";
import BaseCheckBox from "../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import {
  useGetRecipeQuery,
  usePrintRecipeMutation,
} from "../../../../../store/apis/PlateFulRecipesApis";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";
import { QtyInputs, Checkboxes } from "./forms.constants";
import { BackButton } from "components/shared/BackButton";

export default function RecipePrint() {
  const [searchParams] = useSearchParams();
  const [recipeId, setRecipeId] = useState(searchParams.get("recipeId"));
  const [useCustomServings, setUseCustomServings] = useState(0);

  const radioGroup = [
    { value: 0, label: "Default # of servings" },
    { value: 1, label: "Custom # of servings" },
  ];

  const { data: currentRecipeData } = useGetRecipeQuery(recipeId, {
    skip: !recipeId,
  });

  const [
    printRecipe,
    {
      isError: printError,
      isSuccess: printSuccess,
      isLoading: printLoading,
      data: report,
    },
  ] = usePrintRecipeMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const newRecipeId = watch("recipeId");
    if (newRecipeId !== undefined && newRecipeId !== recipeId) {
      setRecipeId(newRecipeId);
    }
  }, [watch("recipeId")]);

  useEffect(() => {
    setUseCustomServings(watch("useCustomServings"));
  }, [watch("useCustomServings")]);

  useEffect(() => {
    if (printSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const onSubmit = (data) => {
    const customServings = parseInt(useCustomServings) !== 0;
    data.recipeId = recipeId;
    data.useCustomServings = customServings;
    printRecipe(data);
  };

  return (
    <>
      <Box
        sx={{
          height: { sm: "60vh", md: "65vh", lg: "70vh", xl: "80vh" },
          backgroundColor: "white !important",
        }}>
        <Grid
          container
          spacing={2}
          sx={{
            padding: "20px",
          }}>
          <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
            <BackButton />
          </Grid>
          <Grid item xs={9} sm={9} md={10} lg={10} xl={10}></Grid>
          <Grid item xs={6}>
            <Typography fontSize={"20px"} fontWeight={700}>
              {currentRecipeData?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <BaseRadio
              label={"Servings"}
              id={"useCustomServings"}
              name={"useCustomServings"}
              control={control}
              defaultValue={0}
              options={radioGroup}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}>
            {parseInt(useCustomServings) === 1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                {QtyInputs.map((data) => {
                  const props = data?.props;
                  return (
                    <BaseInput
                      key={props.name}
                      type="number"
                      sx={{ marginBottom: "10px" }}
                      label={props.label}
                      name={props.name}
                      placeholder={props.label}
                      control={control}
                      errors={errors}
                    />
                  );
                })}
              </Box>
            )}
          </Grid>
          <Grid item xs={9} />
          <Grid item xs={6}>
            {Checkboxes.map((data) => {
              const props = data?.props;
              return (
                <BaseCheckBox
                  key={props.id}
                  name={props.name}
                  id={props.id}
                  label={props.label}
                  defaultValue={false}
                  control={control}
                  errors={errors}
                />
              );
            })}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={printLoading}
                text={"Print"}
                className="baseButton"
              />
            </Box>
            {printError ? (
              <Typography
                sx={{
                  color: "error",
                }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
          <Grid item xs={9} />
        </Grid>
      </Box>
    </>
  );
}
