// react imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
// custom css
import "../../../myRoster.css";
// MUI components
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
// MUI Icons
import ClearIcon from "@mui/icons-material/Clear";
// Shared Components
import GeneratedForm from "../../../../../../shared/generatedForm/GeneratedForm";
import HeaderBox from "../../../../../shared/HeaderBox";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
// form constants
import {
  KitchenFields,
  MealFields,
  observerFieldText,
  consultSummaryFields,
  MealObservation_ScreenNames,
} from "./form.constants";
import { SCREEN_CONSTANT } from "../../../constants";
// api imports
import {
  useAddVisitReportMutation,
  useGetVisitReportsByIdQuery,
  useGetVisitReportsTaskQuery,
  useUpdateVisitReportMutation,
} from "../../../../../../../store/apis/ConsultantApis";
import dayjs from "dayjs";
// import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import size from "lodash/size";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import MealObservationAccordion from "./MealObservationAccordion";
import { isUndefined } from "lodash";

// Component starts from here
function MealObservation() {
  // File state
  const [file, setFile] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  // const [fileName, setFileName] = useState();
  // const [filePreview, setFilePreview] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [isErrors, setIsErrors] = useState(false);

  // Search Params from React Router dom
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const visitId = searchParams.get("visitId");
  const visitReportTypeId = searchParams.get("visitReportTypeId");
  const id = searchParams.get("Id");
  const isEdit = !!id;
  const navigate = useNavigate();

  // unsaved changes functionality here
  const [isChange, setIsChange] = useState(false);

  // ObserverText Conditions
  const observerTextCheck =
    visitReportTypeId == 2
      ? observerFieldText.mealObservation
      : visitReportTypeId == 3
      ? observerFieldText.kitchenObservation
      : visitReportTypeId == 6
      ? observerFieldText.consultSummary
      : visitReportTypeId == 1
      ? observerFieldText.consultSummary
      : "";

  // GeneratedForm Conditions
  const generatedFormCheck =
    visitReportTypeId == 2
      ? MealFields
      : visitReportTypeId == 3
      ? KitchenFields
      : visitReportTypeId == 6
      ? consultSummaryFields
      : visitReportTypeId == 1
      ? consultSummaryFields
      : null;

  // API Call here
  const { data } = useGetVisitReportsTaskQuery(visitReportTypeId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: reportData } = useGetVisitReportsByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [
    addVisitReport,
    { isLoading: addLoading, isError: addError, isSuccess: addSuccess },
  ] = useAddVisitReportMutation();

  const [
    updateVisitReport,
    { error: updateError, isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateVisitReportMutation();

  const isLoading = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;
  const isError = addError || updateError;

  useEffect(() => {
    if (isEdit && reportData) {
      const isDate =
        dayjs(reportData?.description, "h:mm A").format("HH:mm") !==
        "Invalid Date";
      setFile(reportData?.attachments);
      setAttachments(reportData?.attachments);
      const resultObject = {};
      reportData?.configs?.forEach((obj) => {
        resultObject[`hasSeeRecommendation-${obj.taskId}`] =
          obj.seeRecommendation;
        resultObject[`hasComment-${obj.taskId}`] = obj.comment;
        resultObject[`hasMeetsStandard-${obj.taskId}`] = obj.meetsStandard;
        resultObject[`hasNeedsCorrection-${obj.taskId}`] = obj.needsCorrection;
        resultObject[`hasCompleteNext-${obj.taskId}`] = obj.completeNextConsult;
        resultObject[`hasCompleted-${obj.taskId}`] = obj.completed;
        resultObject[`id-${obj.taskId}`] = obj.id;
      });

      const newObject = {
        reportDescription: isDate
          ? dayjs(reportData?.description, "hh:mm A").format("HH:mm")
          : reportData?.description,
        id: id,
        visitId: visitId,
        visitReportType: reportData?.visitReportType,
        confidential: reportData?.includeSignatureLine,
        ...resultObject,
      };
      reset(newObject);
      setInitialValues(newObject);
    }
  }, [id, reportData]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      window.history.back();
      navigate(`/Consultant/MyRosters/VisitDetails?visitPlanId=${visitPlanId}`);
    }
  }, [isSuccess, navigate, isError]);

  // Task IDs in an array from data
  const taskIds = data
    ? data.flatMap((sect) =>
        sect.items
          ? sect.items.flatMap((item) =>
              item.configs
                ? item.configs.map((config) => config?.taskId).filter(Boolean)
                : []
            )
          : []
      )
    : [];

  // useForm Hooks destructuring
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    method: "all",
    shouldUnregister: false,
  });

  // useEffect(() => {
  //   setIsChange(isDirty);
  // }, [isDirty]);

  // Transform Data logic here
  function transformData(rawData) {
    console.log("rawData?.confidential", isUndefined(rawData?.confidential));
    const description =
      typeof rawData?.reportDescription === "object"
        ? dayjs(rawData.reportDescription).format("hh:mm A")
        : rawData.reportDescription;
    const payload = {
      id: id || 0,
      visitReportTypeId,
      visitReportType: rawData?.visitReportType ?? "string",
      visitId,
      description: description,
      includeSignatureLine: isUndefined(rawData?.confidential)
        ? false
        : rawData?.confidential,
      reportDescription: description,
      attachmentUrl: rawData?.attachmentUrl || "string",
      attachments: attachments ?? [],
      configs: [],
      files: [],
    };

    // pushing objects into configs array by comparing with taskIds
    taskIds?.forEach((taskId) => {
      const taskIdString = taskId.toString();
      const matchingKey = Object.keys(rawData).find((key) =>
        key.endsWith(`-${taskIdString}`)
      );
      if (matchingKey) {
        const config = {
          taskId,
          id: rawData[`id-${taskIdString}`] || 0,
          comment: rawData[`hasComment-${taskIdString}`] ?? " ",
          completeNextConsult:
            rawData[`hasCompleteNext-${taskIdString}`] ?? false,
          completed: rawData[`hasCompleted-${taskIdString}`] ?? false,
          meetsStandard: rawData[`hasMeetsStandard-${taskIdString}`] ?? false,
          needsCorrection:
            rawData[`hasNeedsCorrection-${taskIdString}`] ?? false,
          seeRecommendation:
            rawData[`hasSeeRecommendation-${taskIdString}`] ?? false,
        };

        payload.configs.push(config);
      }
    });
    return payload;
  }

  if (!data) {
    return (
      <Box className="loader">
        <CircularProgress />
      </Box>
    );
  }

  const handleFileUpload = (e) => {
    if (e.target.files) {
      const files = e.target.files;
      const maxLimitFiles = size(files) + size(file);
      maxLimitFiles > 10 ? setIsErrors(true) : setIsErrors(false);
      maxLimitFiles <= 10
        ? setFile((prev) => (prev ? [...prev, ...files] : [...files]))
        : null;
    }
  };
  const handleUndoFileSelection = (items) => {
    const deleteFile = file.filter((item) =>
      items.name ? item.name != items.name : item.id !== items.id
    );
    setFile(deleteFile);

    items?.attachmentUrl
      ? setAttachments((prevAttachments) =>
          prevAttachments.map((attachment) =>
            attachment.id === items.id
              ? { ...attachment, isToDelete: true }
              : attachment
          )
        )
      : null;
  };

  // On Submit function
  const onSubmit = (data) => {
    setIsChange(false);
    if (data) {
      const transformedData = transformData(data);

      // Create a new FormData object
      let dataToSend = new FormData();

      Object.entries(transformedData).forEach(([property, value]) => {
        if (property === "files") {
          file?.forEach((file) => {
            file.name ? dataToSend.append("files", file) : null;
          });
        } else if (Array.isArray(value)) {
          // Handle arrays (e.g., configs array)
          value.forEach((item, index) => {
            Object.entries(item).forEach(([subKey, subValue]) => {
              dataToSend.append(`${property}[${index}][${subKey}]`, subValue);
            });
          });
        } else {
          dataToSend.append(property, value);
        }
      });

      // Attach the file to the FormData object
      dataToSend.append("files", file);

      if (data && !isEdit) {
        addVisitReport(dataToSend);
      } else if (data && isEdit) {
        updateVisitReport(dataToSend);
      }
    }
  };

  return (
    <>
      <BaseBlockerModal
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        watch={watch}
        hasChange={isChange}
        setHasChange={setIsChange}
        getValues={getValues}
        dirtyFields={dirtyFields}
      />
      <Box
        sx={{
          padding: "5px",
        }}>
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={MealObservation_ScreenNames}
          observerTextCheck={observerTextCheck}
        />

        <Box
          sx={{
            maxWidth: "20vw",
            marginBottom: "8px",
          }}>
          <GeneratedForm
            list={generatedFormCheck}
            control={control}
            errors={errors}
          />
        </Box>

        <MealObservationAccordion
          data={data}
          visitReportTypeId={visitReportTypeId}
          watch={watch}
          control={control}
          errors={errors}
        />

        {isErrors ? (
          <Typography sx={{ color: "red", marginTop: "10px" }}>
            {" "}
            You can attach a maximum of 10 files{" "}
          </Typography>
        ) : null}
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Tooltip
            title={
              size(file) >= 10 ? "You can attach a maximum of 10 files" : null
            }
            placement="top">
            <>
              <Button
                component="label"
                variant="outlined"
                startIcon={<span className="material-icons">attachment</span>}
                sx={{ marginRight: "1rem", textTransform: "none" }}
                disabled={size(file) >= 10 ? true : false}>
                {`Select File`}
                <input
                  type="file"
                  accept=".pdf"
                  hidden
                  multiple
                  onChange={handleFileUpload}
                />
              </Button>
            </>
          </Tooltip>

          <BaseSubmitButton
            onClick={handleSubmit(onSubmit)}
            isSubmitting={isLoading}
            text="Save"
          />
        </Box>

        {file?.map((file, index) => (
          <Box
            key={index}
            sx={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
            }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<span className="material-icons">attachment</span>}
              sx={{ marginRight: "1rem", textTransform: "none" }}>
              {file.name ? file.name : file.attachmentUrl.split("/").pop()}
            </Button>
            <span style={{ cursor: "pointer" }}>
              {file && (
                <ClearIcon
                  variant="outlined"
                  color="error"
                  onClick={() => handleUndoFileSelection(file)}
                />
              )}
            </span>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default MealObservation;
