import React, { useState } from "react";
import {
  useRecalculateNutrientAnalysisMutation,
  useRecipeNutrientAnalysisQuery,
} from "../../../../../store/apis/RecipeAPIs";
import { useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { Card, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { BaseButton } from "components/shared/BaseButton";
import { DMStyles } from "styles/Styles";
import BaseInput from "components/shared/baseForm/BaseInput";
import { useGetHepContributionsByRecipeQuery } from "store/apis/MenuApis";

export default function RecipeNutrientAnalysis() {
  const showHep = process.env.REACT_APP_SHOW_HEP === "true";

  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const [showMore, setShowMore] = useState(false);
  const {
    data: results,
    isFetching: loading,
    // isError,
  } = useRecipeNutrientAnalysisQuery(recipeId, {
    skip: !recipeId,
  });

  const { data: hepContributions, isLoading: hepLoading } =
    useGetHepContributionsByRecipeQuery(recipeId, {
      skip: !recipeId,
    });

  const load = loading || hepLoading;

  const [recalculate, { isLoading: isSubmitting }] =
    useRecalculateNutrientAnalysisMutation();

  const nutrients = results?.data?.nutrients?.map((item) => ({
    ...item,
    id: item?.nutrientId,
  }));

  //  split array by half
  const splitNutrientsByMacroMicro = (array) => {
    const macros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === true
    );
    const micros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === false
    );
    const truncatedMicros = micros?.slice(0, 9);
    return [macros, showMore ? micros : truncatedMicros];
  };

  const splitNutrientDetailsByMacroMicro = (array) => {
    const macros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === true)
    );
    const micros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === false)
    );
    return [macros, micros];
  };

  const nutrientsData = splitNutrientsByMacroMicro(nutrients);

  const detailedNutrients = results?.data?.nutrientDetails?.map((item) => ({
    ...item,
    id: `${item?.nutrientId}_${item?.ingredientId}_${crypto.randomUUID()}`,
  }));
  // Group objects by sortOrder and store it in array
  const groupByNutrientId = (array) => {
    return array?.reduce((acc, item) => {
      const { sortOrder } = item;
      if (!acc[sortOrder]) {
        acc[sortOrder] = [];
      }
      acc[sortOrder].push(item);
      return acc;
    }, {});
  };
  const groupedNutrients = Object.values(
    groupByNutrientId(detailedNutrients) || []
  );

  const nutrientsDetailsData =
    splitNutrientDetailsByMacroMicro(groupedNutrients);

  const incompleteNutritionalAnalysisBanner = (
    <div
      style={{
        fontWeight: "800",
        fontSize: "20px",
        color: "#DE6D00",
        backgroundColor: "#DE6D001A",
        padding: "16px",
        margin: "8px",
        borderRadius: "4px",
      }}>
      Nutritional Analysis is incomplete due to missing information in some
      ingredients in this recipe.
    </div>
  );

  return (
    <>
      {load ? (
        <EditSkeleton />
      ) : (
        <Card
          sx={{
            height: { md: "90%", xl: "90%" },
            overflow: "auto",
          }}>
          <Box
            sx={{
              padding: "5px",
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}>
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  lineHeight: "43.65px",
                }}>
                {results?.data?.recipeName}
              </Typography>
              {/* <WarningText
              margin={"5px"}
              sx={{ marginLeft: "0px !important" }}
              text={
                "Please see Menu Instruction Booklet for Menu/Recipe Nutrient Analysis limitations."
              }
              width={"60%"}
            /> */}
            </Box>
            {results?.data?.nutrients?.some((obj) => obj.problems !== 0)
              ? incompleteNutritionalAnalysisBanner
              : null}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: "600",
                    lineHeight: "27.28px",
                  }}>
                  {results?.data?.servingSize
                    .replace(/[()]/g, "") // Remove the parentheses
                    .replace("ServingSize", "Serving Size")}
                </Typography>
                <BaseButton
                  text={"Recalculate"}
                  isSubmitting={isSubmitting}
                  onClick={() => recalculate(recipeId)}
                  sx={{ backgroundColor: "#008385" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginY: "10px",
                    width: "90%",
                  }}>
                  {nutrientsData.map((data, ind) => (
                    <Box
                      key={ind}
                      sx={{
                        width: "49%",
                      }}>
                      {data.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid black",
                          }}>
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                            }}>
                            {item?.nutrient?.name}
                          </Typography>
                          <Typography
                            variant="span"
                            sx={{
                              fontSize: "14px",
                            }}>
                            {item?.quantity +
                              " " +
                              item?.nutrient.unitOfMeasure}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
              {nutrients.length !== 0 ? (
                <BaseButton
                  sx={{
                    height: "25px",
                    width: "225px",
                    backgroundColor: "#FFFFFF",
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginBottom: "15px",
                  }}
                  fontColor={"#000000"}
                  border={"1px solid #000000"}
                  onClick={() => setShowMore(!showMore)}
                  text={
                    showMore
                      ? "Show Fewer Micronutrients"
                      : "Show More Micronutrients"
                  }
                />
              ) : null}
            </Box>
            {showHep ? (
              <>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "700",
                      color: DMStyles.Black,
                      borderRadius: "5px",
                      padding: "5px",
                      backgroundColor: "#C8DCE3",
                    }}>
                    Healthy Eating Patterns
                  </Typography>
                </Box>

                <Box width={"100%"}>
                  <Grid container padding={1} spacing={2}>
                    {hepContributions?.map((item, index) => (
                      <Grid item xs={12} md={4} lg={4} xl={4} key={index}>
                        <Box
                          display={"flex"}
                          width={"100%"}
                          gap={3}
                          alignItems={"center"}>
                          <Typography
                            variant="h6"
                            component="h6"
                            fontWeight="bold">
                            {item?.hepTextName}
                          </Typography>
                          <Box width={"20%"}>
                            <BaseInput
                              type="number"
                              size="small"
                              disabled
                              value={item?.hepFinalContributions}
                            />
                          </Box>
                        </Box>
                        <Typography variant="p" fontSize={"14px"}>
                          {item?.description}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            ) : null}
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  color: DMStyles.Black,
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor: "#C8DCE3",
                }}>
                Details By Nutrients
              </Typography>

              <Box
                sx={{
                  paddingX: "10px",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                {nutrientsDetailsData?.map((nutrient, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: "49%",
                      border: "2px solid black",
                      borderRadius: "10px",
                      padding: "16px",
                    }}>
                    <div style={{ fontSize: "24px", fontWeight: "700" }}>
                      {i === 0 ? "Macronutrients" : "Micronutrients"}
                    </div>
                    {nutrient.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginY: "5px",
                          borderBottom: " 1px solid black",
                        }}>
                        <Typography
                          sx={{
                            width: "30%",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "24px",
                          }}>
                          {data[0]?.nutrient}
                        </Typography>
                        <Box
                          sx={{
                            width: "60%",
                          }}>
                          {data.map((item, ind) => (
                            <Box
                              key={ind}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                              <Typography
                                variant="p"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  lineHeight: "19px",
                                  fontWeight: "500",
                                }}>
                                {item.problems ? (
                                  <Tooltip title={item.problems}>
                                    <span
                                      style={{
                                        marginRight: "-18px",
                                        color: "#DE6D00",
                                      }}
                                      className="material-icons">
                                      info_outlined
                                    </span>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                {item.ingredient}
                              </Typography>
                              <Typography
                                variant="span"
                                sx={{
                                  fontSize: "14px",
                                }}>
                                {item?.nutrientQuantity.toFixed(2) + " "}
                                {item?.baseUnit === "gram"
                                  ? "g"
                                  : item?.baseUnit}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
}
