import React from "react";
import { FIELD_OPTION_DEFAULTS, FIELD_OPTION_FORM } from "./forms.constants";
import GenericFieldForm from "./GenericFieldForm";

export default function FieldOption({
  nestIndex,
  sectionIndex,
  fieldGroupIndex,
  fieldIndex,
}) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${fieldIndex}].fields[${nestIndex}].options`}
      fieldList={FIELD_OPTION_FORM}
      appendObjectExample={FIELD_OPTION_DEFAULTS}
      nameOfObject={"Option"}
      fieldPropName={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${fieldIndex}].fields[${nestIndex}].options`}
      isSortOrder={false}></GenericFieldForm>
  );
}
