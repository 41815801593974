// import { FORM_TYPES } from "../../../../../../shared/generatedForm/GeneratedForm";
import {
  MAX_LENGTH_VALIDATION,
  REQUIRED_ERROR,
} from "../../../../../../../utils/errorMessages";
import { theme } from "styles/theme";
import { DMStyles } from "styles/Styles";

export const MealFields = [
  {
    props: {
      name: "reportDescription",
      id: "meal",
      label: "Meal",
      placeholder: "Meal",
      size: "small",
      type: "text",
    },
    validationProps: {
      required: REQUIRED_ERROR("Meal Description"),
      maxLength: MAX_LENGTH_VALIDATION("Meal", 200),
    },
    size: 2,
  },
];

export const observerFieldText = {
  mealObservation: {
    name: "Meal Observation",
    color: theme.palette.purple.main,
  },
  kitchenObservation: {
    name: "Kitchen Observation",
    color: theme.palette.blue.main,
  },
  consultSummary: {
    name: "Visit Summary",
    color: theme.palette.green.main,
  },
};

export const KitchenFields = [
  {
    props: {
      name: "reportDescription",
      id: "reportDescription",
      label: "Description",
      placeholder: "Description",
      size: "small",
      type: "text",
    },
    size: 2,
  },
];

export const consultSummaryFields = [
  {
    props: {
      name: "reportDescription",
      id: "summary",
      label: "Report Description",
      placeholder: "Report Description",
      size: "small",
      type: "text",
    },
    validationProps: {
      required: REQUIRED_ERROR("Report Description"),
      maxLength: {
        value: 200,
        message:
          "Report Description should not be greater than 200 characters.",
      },
    },
    size: 2,
  },
  // {
  //   props: {
  //     name: "confidential",
  //     id: "confidential",
  //     label: "Include Signature Line",
  //     fontSize: "15px",
  //     defaultValue: true,
  //   },
  //   validationProps: {},
  //   type: FORM_TYPES.Checkbox,
  // },
];

export const MealObservation_ScreenNames = [
  {
    id: 1,
    screenName: false,
    bgColor: theme.palette.primary.main,
    fontWeight: "600",
    color: DMStyles.White,
    marginBottom: "5px",
  },
  {
    id: 2,
    screenName: "Meal Observation",
    bgColor: theme.palette.orange.main,
    fontWeight: "500",
    color: DMStyles.White,
    marginBottom: "0px",
  },
];
