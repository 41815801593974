import { Box, Grid2, useTheme } from "@mui/material";
import React from "react";
import Section from "./Section";
import BaseBanner from "components/shared/baseBanner/BaseBanner";

export default function SectionGroup({ sectionGroup }) {
  const theme = useTheme();

  console.log(sectionGroup?.name !== "-");

  if (sectionGroup?.name !== "-") {
    return (
      <Box width={"100%"}>
        <BaseBanner
          width={"100%"}
          fontWeight={"600"}
          titleProps={{
            variant: "h5",
            color: theme.palette.primary.contrastText,
          }}
          subTitle={sectionGroup?.description}
          subTitleProps={{
            color: theme.palette.primary.contrastText,
          }}
          bgColor={theme.palette.primary.light}
          color={theme.palette.primary.dark}
          title={sectionGroup?.name}
        />

        <Box p={1}>
          <Grid2 container>
            {sectionGroup?.sections?.map((section) => (
              <Section key={section.id} section={section} />
            ))}
          </Grid2>
        </Box>
      </Box>
    );
  }

  return (
    <Grid2 width={"100%"} container>
      {sectionGroup?.sections?.map((section) => (
        <Section key={section.id} section={section} />
      ))}
    </Grid2>
  );
}
