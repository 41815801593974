import { Box, Grid } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import { BackButton } from "components/shared/BackButton";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddVisitReportTypeMutation,
  useGetVisitReportTypeByIdQuery,
  useUpdateVisitReportTypeMutation,
} from "store/apis/VisitReportAPIs";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import {
  VISIT_REPORT_TYPE_DEFAULTS,
  VISIT_REPORT_TYPE_FORM,
} from "./addVisitReportType.constants";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import SectionGroup from "./forms/SectionGroup";
import { WarningText } from "components/shared/WarningText";

export default function AddVisitReportType() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const isEdit = !!id;

  const { data, isLoading } = useGetVisitReportTypeByIdQuery(id, {
    skip: !id,
  });

  const [addVisitReportType, { isSuccess: addSuccess, isLoading: addLoading }] =
    useAddVisitReportTypeMutation();

  const [
    updateVisitReportType,
    { isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateVisitReportTypeMutation();

  const isEditLoading = isEdit && isLoading;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitLoading = addLoading || updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: VISIT_REPORT_TYPE_DEFAULTS,
  });

  // Destructing
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!isLoading && data) {
      reset(data);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/admin/dietitian/visitReportBuilder");
    }
  }, [isSuccess]);

  function convertEmptyStringsToNull(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        convertEmptyStringsToNull(obj[key]);
      } else if (key === "triggers" && obj[key] === "") {
        obj[key] = null;
      }
    }
  }

  function onSubmit(data) {
    convertEmptyStringsToNull(data);

    if (isEdit) {
      updateVisitReportType(data);
      return;
    }
    addVisitReportType(data);
    return;
  }

  return (
    <Box>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <WarningText
            margin={"0"}
            text={`Fields with "-" as names will not be displayed (but the children will be displayed)`}
          />
          <FormProvider {...methods}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignContent={"center"}>
              <BackButton />
              <BaseSubmitButton
                isSubmitting={isSubmitLoading}
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <GeneratedForm
                errors={errors}
                control={control}
                list={VISIT_REPORT_TYPE_FORM}
              />
            </Grid>
            <SectionGroup />
          </FormProvider>
        </Box>
      )}
    </Box>
  );
}
