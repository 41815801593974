import React from "react";

import DietitianEdit from "components/admin/groupMenu/userMenu/userSetup/dietitianForm/DietitianForm";

// admin/SetupMenu

// admin/SetupMenu/mealCardSetup
import MealCardSetup from "components/admin/setupMenu/mealCardSetup/MealCardSetup";
import AdaptiveEquipment from "components/admin/setupMenu/mealCardSetup/AdaptiveEquipment/AdaptiveEquipment";
import AddAdaptiveEquipment from "components/admin/setupMenu/mealCardSetup/AdaptiveEquipment/AddAdaptiveEquipment/AddAdaptiveEquipment";
import LiquidConsistency from "components/admin/setupMenu/mealCardSetup/LiquidConsistency/LiquidConsistency";
import AddLiquidConsistency from "components/admin/setupMenu/mealCardSetup/LiquidConsistency/addLiquidConsistency/addLiquidConsistency";
import AddBeverage from "components/admin/setupMenu/mealCardSetup/Beverages/addBeverage/AddBeverage";
import Allergies from "components/admin/setupMenu/mealCardSetup/Allergies/Allergies";
import AddAllergy from "components/admin/setupMenu/mealCardSetup/Allergies/addAllergy/AddAllergy";

// admin/SetupMenu/recipeSetup
import RecipeSetup from "components/admin/setupMenu/recipeSetup/RecipeSetup";
import Diets from "components/admin/setupMenu/recipeSetup/Diets/Diets";
import AddDiet from "components/admin/setupMenu/recipeSetup/Diets/addDiet/AddDiet";
import Ingredients from "components/admin/setupMenu/recipeSetup/Ingredients/Ingredients";
import AddIngredient from "components/admin/setupMenu/recipeSetup/Ingredients/addIngredient/AddIngredient";
import IngredientUsage from "components/admin/setupMenu/recipeSetup/Ingredients/IngredientUsage";
import VendorIngredient from "components/admin/setupMenu/recipeSetup/Ingredients/vendorIngredient/VendorIngredient";
import AddVendorIngredient from "components/admin/setupMenu/recipeSetup/Ingredients/vendorIngredient/addVendorIngredient";
import CopyVendorIngredient from "components/admin/setupMenu/recipeSetup/Ingredients/vendorIngredient/CopyVendorIngredient";
import IngredientAllergy from "components/admin/setupMenu/recipeSetup/Ingredients/ingredientAllergy/IngredientAllergy";
import AddIngredientAllergy from "components/admin/setupMenu/recipeSetup/Ingredients/ingredientAllergy/addIngredientAllergy";
import AddTag from "components/admin/setupMenu/recipeSetup/Tags/addTags/AddTag";
import StandardText from "components/admin/setupMenu/recipeSetup/standardText/StandardText";
import EditStandardText from "components/admin/setupMenu/recipeSetup/standardText/editStandardText/EditStandardText";
import RecipeCategories from "components/admin/setupMenu/recipeSetup/recipeCategories/RecipeCategories";
import AddRecipeCategory from "components/admin/setupMenu/recipeSetup/recipeCategories/addRecipeCategory/AddRecipeCategory";
import Tags from "components/admin/setupMenu/recipeSetup/Tags/Tags";
import CorporateGroupCategories from "components/admin/setupMenu/recipeSetup/recipeCategories/corporateGroupCategories/CorporateGroupCategories";
import AddCorporateGroupCategory from "components/admin/setupMenu/recipeSetup/recipeCategories/corporateGroupCategories/AddCorporateGroupCategory";
import UnitConversion from "components/admin/setupMenu/recipeSetup/Ingredients/unitConversion/UnitConversion";
import AddUnitConversion from "components/admin/setupMenu/recipeSetup/Ingredients/unitConversion/AddUnitConversion";
import AddHep from "components/admin/setupMenu/recipeSetup/Ingredients/addHep/AddHep";

// admin/SetupMenu/dietitianSetup
import DietitianSetup from "components/admin/setupMenu/dietitianSetup/DietitianSetup";
import CommunityDietitian from "components/admin/setupMenu/dietitianSetup/communityDietitian/CommunityDietitian";
import Region from "components/admin/setupMenu/dietitianSetup/region/Region";
import DietitianRegion from "components/admin/setupMenu/dietitianSetup/dietitianRegion/DietitianRegion";
import UnassignedCommunities from "components/admin/setupMenu/dietitianSetup/unassignedCommunities/UnassignedCommunities";
import AddRegion from "components/admin/setupMenu/dietitianSetup/region/addRegion/AddRegion";
import UnlockFinishedReports from "components/admin/setupMenu/dietitianSetup/unlockFinishedReports/UnlockFinishedReports";

// admin/SetupMenu/mealCardSetup
import FoodTypes from "components/admin/setupMenu/mealCardSetup/FoodTypes/FoodTypes";
import AddFoodType from "components/admin/setupMenu/mealCardSetup/FoodTypes/addFoodType/AddFoodType";
import Beverages from "components/admin/setupMenu/mealCardSetup/Beverages/Beverages";
import MealExtras from "components/admin/setupMenu/mealCardSetup/MealExtras/MealExtras";
import AdminAddMealExtras from "components/admin/setupMenu/mealCardSetup/MealExtras/AdminAddMealExtras";
import VisitReportBuilder from "components/admin/setupMenu/dietitianSetup/visitReportBuilder/VisitReportBuilder";
import AddVisitReportType from "components/admin/setupMenu/dietitianSetup/visitReportBuilder/addVisitReportType/AddVisitReportType";
import IngredientMenu from "components/admin/setupMenu/recipeSetup/Ingredients/IngredientMenu";
import PreviewVisitReportType from "components/admin/setupMenu/dietitianSetup/visitReportBuilder/previewVisitReportType/PreviewVisitReportType";

const AdminMealCardRoute = {
  path: "MealCard",
  element: <MealCardSetup />,
  children: [
    { path: "Beverages", element: <Beverages /> },
    { path: "Beverages/addbeverages", element: <AddBeverage /> },
    { path: "mealExtras", element: <MealExtras /> },
    {
      path: "mealExtras/adminAddMealExtras",
      element: <AdminAddMealExtras />,
    },
    { path: "liquidconsistency", element: <LiquidConsistency /> },
    {
      path: "liquidconsistency/addliquid",
      element: <AddLiquidConsistency />,
    },
    { path: "allergies", element: <Allergies /> },
    { path: "allergies/addallergy", element: <AddAllergy /> },
    { path: "AdaptiveEquipment", element: <AdaptiveEquipment /> },
    {
      path: "AdaptiveEquipment/AddAdaptiveEquipment",
      element: <AddAdaptiveEquipment />,
    },
    { path: "FoodTypes", element: <FoodTypes /> },
    {
      path: "FoodTypes/AddFoodType",
      element: <AddFoodType />,
    },
  ],
};

const AdminRecipesRoute = {
  path: "Recipes",
  element: <RecipeSetup />,
  children: [
    { path: "Diets", element: <Diets /> },
    { path: "Diets/addDiet", element: <AddDiet /> },
    { path: "Ingredients", element: <Ingredients /> },
    {
      path: "Ingredients/IngredientUsage",
      element: <IngredientUsage />,
    },
    {
      path: "Ingredients/ingredientAllergy",
      element: <IngredientAllergy />,
    },
    {
      path: "Ingredients/vendorIngredient/addVendorIngredient",
      element: <AddVendorIngredient />,
    },
    {
      path: "Ingredients/vendorIngredient/CopyVendorIngredient",
      element: <CopyVendorIngredient />,
    },
    { path: "Tags", element: <Tags /> },
    { path: "Tags/addTag", element: <AddTag /> },
    { path: "Categories", element: <RecipeCategories /> },
    {
      path: "Categories/addRecipeCategory",
      element: <AddRecipeCategory />,
    },
    {
      path: "Categories/CorporateGroupCategories",
      element: <CorporateGroupCategories />,
    },
    {
      path: "Categories/CorporateGroupCategories/AddCorporateGroup",
      element: <AddCorporateGroupCategory />,
    },
    { path: "StandardText", element: <StandardText /> },
    {
      path: "StandardText/EditStandardText",
      element: <EditStandardText />,
    },
    {
      path: "IngredientDetails",
      element: <IngredientMenu />,
      children: [
        {
          path: "addIngredient",
          element: <AddIngredient />,
        },
        { path: "unitConversion", element: <UnitConversion /> },
        {
          path: "unitConversion/AddUnitConversion",
          element: <AddUnitConversion />,
        },
        {
          path: "ingredientAllergy",
          element: <IngredientAllergy />,
        },
        {
          path: "ingredientAllergy/addIngredientAllergy",
          element: <AddIngredientAllergy />,
        },
        {
          path: "IngredientUsage",
          element: <IngredientUsage />,
        },
        {
          path: "vendorIngredient",
          element: <VendorIngredient />,
        },
        {
          path: "addHep",
          element: <AddHep />,
        },
      ],
    },
  ],
};

const AdminDietitianRoute = {
  path: "Dietitian",
  element: <DietitianSetup />,
  children: [
    {
      path: "CommunityDietitian",
      element: <CommunityDietitian />,
    },
    { path: "Region", element: <Region /> },
    { path: "Region/AddRegion", element: <AddRegion /> },
    { path: "DietitianRegion", element: <DietitianRegion /> },
    {
      path: "DietitianRegion/dietitianEdit",
      element: <DietitianEdit />,
    },
    {
      path: "UnassignedCommunities",
      element: <UnassignedCommunities />,
    },
    {
      path: "UnlockFinishedReports",
      element: <UnlockFinishedReports />,
    },
    {
      path: "visitReportBuilder",
      element: <VisitReportBuilder />,
    },
    {
      path: "visitReportBuilder/addReportType",
      element: <AddVisitReportType />,
    },
    {
      path: "visitReportBuilder/previewReportType",
      element: <PreviewVisitReportType />,
    },
  ],
};

export const AdminSetupsRoute = {
  AdminDietitianRoute,
  AdminRecipesRoute,
  AdminMealCardRoute,
};
