import { useIsAuthenticated } from "@azure/msal-react";
// MUI Imports
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// Hooks Imports
import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// Custom Hooks
import DocumentManagement from "./settings/DocumentManagement";
import LicenseSetup from "./settings/LicenseSetup";
import RatesSetup from "./settings/RatesSetup";
import RegionManagement from "./settings/RegionManagement";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { EditSkeleton } from "../../../../admin.overlays";
import { infoTextFields } from "./forms.constants";
import { setErrorsForProperties } from "../../../../../shared/utils/helpers";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { BaseButton } from "components/shared/BaseButton";
import "./DietitianForm.css";
// API Section
import {
  useAddDietitianUserMutation,
  useGetDietitianLicensesQuery,
  useGetDietitianTypesQuery,
  useGetDietitianUserByUserIdQuery,
  useUpdateDietitianUserMutation,
} from "../../../../../../store/apis/DietitianAPis";
import { useGetCurrentUserQuery } from "../../../../../../store/apis/UserApis";
// Dayjs Imports
import dayjs from "dayjs";
// lodash
import isNull from "lodash/isNull";
import {
  CreditCard,
  Description,
  ExpandMore,
  KeyboardArrowLeft,
  LocationOn,
  NoteAlt,
  Sell,
} from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function DietitianForm() {
  // Local State Declaration
  const [currentDietitian, setCurrentDietitian] = useState();
  const [expanded, setExpanded] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const currentUserId = searchParams.get("id");
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();
  // UseForm Hook declaration
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: currentDietitian,
  });
  const {
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    setError,
    formState: { errors },
  } = methods;
  // Query Api Section
  const {
    data: currentDietitianData,
    isFetching: loadingDietitian,
    isSuccess: currentDietitianSuccess,
    // isError: currentDietitianError,s
  } = useGetDietitianUserByUserIdQuery(currentUserId, {
    skip: !currentUserId,
  });

  const {
    data: currentUserData,
    isFetching: currentUserLoading,
    isSuccess: currentUserSuccess,
  } = useGetCurrentUserQuery(currentUserId);
  const { data: dietitianLicenses, isFetching: loadingLicenses } =
    useGetDietitianLicensesQuery(currentDietitianData?.id, {
      skip: !currentDietitianData?.id,
    });

  const { data: dietitianTypes, isFetching: loadingDietitianTypes } =
    useGetDietitianTypesQuery();

  const [
    addDietitianUser,
    {
      isError: addDietitianUserError,
      isSuccess: addDietitianUserSuccess,
      isLoading: addDietitianUserSubmitting,
      error: addErrorResponse,
    },
  ] = useAddDietitianUserMutation();

  const isDietitianConfigured = currentDietitianSuccess;
  // Mutation section
  const [
    updateDieitianUser,
    {
      isError: updateDieitianUserError,
      isSuccess: updateDieitianUserSuccess,
      isLoading: updateDieitianUserSubmitting,
      error: updateErrorResponse,
    },
  ] = useUpdateDietitianUserMutation();
  // Checks
  const isError = addDietitianUserError || updateDieitianUserError;
  const isSuccess = addDietitianUserSuccess || updateDieitianUserSuccess;
  const isSubmitting =
    addDietitianUserSubmitting || updateDieitianUserSubmitting;
  const errorResponse = addErrorResponse || updateErrorResponse;
  // useEffect Section
  useEffect(() => {
    if (errorResponse) {
      setErrorsForProperties({
        setError: setError,
        responseError: errorResponse,
      });
    }
  }, [errorResponse]);

  useEffect(() => {
    if (currentDietitianData && !loadingDietitian && currentDietitianSuccess) {
      setCurrentDietitian({
        ...currentDietitian, // Copy the old fields
        dietitianId: currentUserId, // But override this one
      });
      setCurrentDietitian(currentDietitianData);
      // setIsEditLoading(false);
      reset(currentDietitianData);

      setCurrentDietitian(currentDietitianData);
    } else if (currentUserData && !currentUserLoading && currentUserSuccess) {
      setCurrentDietitian({
        ...currentDietitian, // Copy the old fields
        dietitianId: currentUserId, // But override this one
        fullName: currentUserData?.firstName + " " + currentUserData?.lastName,
      });
      // setIsEditLoading(false);
      reset({
        ...currentDietitian, // Copy the old fields
        dietitianId: currentUserId, // But override this one
        fullName: currentUserData?.firstName + " " + currentUserData?.lastName,
        sageEmployeeId:
          currentUserData?.lastName + "," + currentUserData?.firstName,
      });
    }
    if (!loadingDietitian && !currentUserLoading) {
      setIsEditLoading(false);
    }
  }, [
    currentUserData,
    currentUserLoading,
    currentUserSuccess,
    currentDietitianData,
    loadingDietitian,
    currentDietitianSuccess,
  ]);

  const onSubmit = (data) => {
    const dateTransformation = {
      startDate: dayjs(data?.startDate).isValid()
        ? dayjs(data?.startDate).format("YYYY-MM-DD")
        : null,
      cdrexpirationDate: dayjs(data?.cdrexpirationDate).isValid()
        ? dayjs(data?.cdrexpirationDate).format("YYYY-MM-DD")
        : null,
      csaexpirationDate: dayjs(data?.csaexpirationDate).isValid()
        ? dayjs(data?.csaexpirationDate).format("YYYY-MM-DD")
        : null,
      proLiabilityExpirationDate: dayjs(
        data?.proLiabilityExpirationDate
      ).isValid()
        ? dayjs(data?.proLiabilityExpirationDate).format("YYYY-MM-DD")
        : null,
    };
    if (isAuthenticated) {
      if (isNull(data?.comments)) {
        data.comments = "";
      }

      if (isDietitianConfigured) {
        updateDieitianUser({
          ...data,
          ...dateTransformation,
        });
        return;
      }
      addDietitianUser({
        ...data,
        ...dateTransformation,
      });
    }
  };

  isSuccess && !isSubmitting ? navigate(-1) : null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}>
        <BaseButton
          sx={{ marginLeft: "0px !important" }}
          text="Back "
          startIcon={<KeyboardArrowLeft />}
          To={`/admin/usermenu/usersetup?search=&archived=false`}
        />
      </Box>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Box
          sx={{
            height: "60vh",
          }}>
          <FlexBox
            justifyContent="space-between"
            alignItems="center"
            padding={1}>
            <Typography variant="h5">
              {loadingDietitian
                ? "Loading..."
                : `${currentDietitian?.fullName || ""} ${
                    isDietitianConfigured ? "" : "(Never Configured)"
                  }`}
            </Typography>
            <Tooltip title="View RD Assignment">
              <Link
                to={`/admin/dietitian/communitydietitian?dietitianId=${currentDietitian?.dietitianId}`}>
                <Button variant="contained" color="warning" icon="true">
                  <NoteAlt />
                </Button>
              </Link>
            </Tooltip>
          </FlexBox>
          <Divider sx={{ marginBottom: "8px" }} />
          <Grid container spacing={2}>
            <FormProvider>
              <Grid item xs={7}>
                <Grid container spacing={1.5}>
                  <GeneratedForm
                    list={infoTextFields}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                  />
                </Grid>
              </Grid>
            </FormProvider>
            <Grid
              item
              xs={5}
              style={{ height: "60vh", overflow: "auto", marginTop: "16px" }}>
              <GenerateAccordian
                expanded={expanded === "Document Management"}
                onChange={setExpanded}
                text={"Document Management"}
                icon={<Description color="primary" />}>
                <DocumentManagement
                  currentDietitian={currentDietitian}
                  isAuthenticated={isAuthenticated}
                />
              </GenerateAccordian>

              <GenerateAccordian
                expanded={expanded === "Region Management"}
                onChange={setExpanded}
                text={"Region Management"}
                className={!errors["primaryRegionId"] ? "" : "errorBorder"}
                icon={<LocationOn color="primary" />}>
                <RegionManagement
                  setValue={setValue}
                  errors1={errors}
                  control1={control}
                  currentDietitian={currentDietitian}
                  loadingDietitianTypes={loadingDietitianTypes}
                  isAuthenticated={isAuthenticated}
                />
              </GenerateAccordian>

              <GenerateAccordian
                expanded={expanded === "Rates Settings"}
                onChange={setExpanded}
                text={"Rates Settings"}
                className={!errors["dietitianTypeId"] ? "" : "errorBorder"}
                icon={<Sell color="primary" />}>
                <RatesSetup
                  dietitianTypes={dietitianTypes}
                  control={control}
                  errors={errors}
                  register={register}
                />
              </GenerateAccordian>

              <GenerateAccordian
                expanded={expanded === "License Settings"}
                onChange={setExpanded}
                text={"License Settings"}
                icon={<CreditCard color="primary" />}>
                <LicenseSetup
                  isAuthenticated={isAuthenticated}
                  currentDietitian={currentDietitian}
                  dietitianLicenses={dietitianLicenses}
                  loadingLicenses={loadingLicenses}
                />
              </GenerateAccordian>

              <FlexBox marginTop={1} justifyContent="end">
                <BaseSubmitButton
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                />
                {isError == true ? (
                  <Typography
                    variant={"p"}
                    sx={{
                      color: "error",
                    }}>
                    An error occurred please check the form again.
                  </Typography>
                ) : (
                  ""
                )}
              </FlexBox>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

function GenerateAccordian(props) {
  const handleChange = () => {
    props.onChange(props.expanded ? "" : props.text);
  };
  return (
    <Accordion expanded={props.expanded} onChange={handleChange}>
      <AccordionSummary
        className={props?.className}
        expandIcon={<ExpandMore />}>
        <FlexBox justifyContent={"start"}>
          {props?.icon}
          {props?.text}
        </FlexBox>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}
