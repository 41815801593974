import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  FACILITIES,
  USERS,
  FACILITY_DIETS,
  FACILITY_CONTRACTS,
  FACILITY_ADAPTIVE_EQUIPMENTS,
  FACILITY_USERS,
  USER_FACILITIES,
  FACILITY_EHRS,
  FACILITY_EHR_DIET_MAPPINGS,
  FACILITY_EHR_LIQUID_MAPPINGS,
  FACILITY_CONSULTINGS,
  FACILITY_THICKENED_LIQUIDS,
  FACILITY_TABLESIDES,
  FACILITY_CUSTOM_MENUS,
  FACILITY_COMMUNICATIONS,
  TABLESIDE_AUTOMATIONS,
  FACILITY_CARE_TYPES,
} = API_ENDPOINTS;

export const FacilityAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilityById: builder.query({
      query: (id) => `/${FACILITIES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITIES, id }],
    }),
    getFacilityLists: builder.query({
      query: () => `/${FACILITIES}/GetFacilityListExport`,
    }),
    getFacilityByName: builder.query({
      query: (searchTerm) =>
        `/${FACILITIES}/searchbyname?searchText=${encodeURIComponent(searchTerm)}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: FACILITIES, id })),
              FACILITIES,
            ]
          : [FACILITIES],
    }),
    getFacilityByUserFacilities: builder.query({
      query: ({ searchTerm }) =>
        `/${FACILITIES}/UserFacilities?searchText=${encodeURIComponent(searchTerm)}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: `${res?.corporateGroup || ""} - ${res?.city} / ${
              res?.state
            }`,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: FACILITIES, id })),
              FACILITIES,
            ]
          : [FACILITIES],
    }),
    getFacilitySelectBox: builder.query({
      query: (searchTerm) =>
        `/${FACILITIES}/searchbyname?searchText=${encodeURIComponent(searchTerm)}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: `${res?.corporateGroup || ""} - ${res?.city} / ${
              res?.state
            }`,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: FACILITIES, id })),
              FACILITIES,
            ]
          : [FACILITIES],
    }),
    searchFacilities: builder.query({
      query: ({
        searchTerm,
        vendorId,
        purchasingGroupId,
        corporateGroupId,
        showArchived,
        skip,
        take,
      }) => {
        let url = `/${FACILITIES}/search?skip=${skip || "0"}&take=${
          take || "100"
        }&searchText=${encodeURIComponent(searchTerm) || ""}&showArchived=${
          showArchived?.toString() || "false"
        }`;
        if (vendorId) {
          url += "&vendorId=" + vendorId;
        } else if (purchasingGroupId) {
          url += "&purchasingGroupId=" + purchasingGroupId;
        } else if (corporateGroupId) {
          url += "&corporateGroupId=" + corporateGroupId;
        }
        return url;
      },
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: FACILITIES, id })),
              FACILITIES,
            ]
          : [FACILITIES],
    }),
    setAsDefaultFacility: builder.mutation({
      // eslint-disable-next-line no-unused-vars
      query: ({ facilityId, userId }) => ({
        url: `/${FACILITIES}/setascurrentcommunity?facilityId=${facilityId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITIES, id: arg.facilityId },
        { type: USERS, id: arg.userId },
      ],
    }),
    addFacility: builder.mutation({
      query: ({ data, ignoreDuplicate }) => ({
        url: `/${FACILITIES}?ignoreSimilarFacilityName=${ignoreDuplicate}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: FACILITIES, id: result.id }],
    }),
    updateFacility: builder.mutation({
      query: (data) => ({ url: `/${FACILITIES}`, method: "PUT", body: data }),
      invalidatesTags: (result) => [{ type: FACILITIES, id: result.id }],
    }),
    deleteFacility: builder.mutation({
      query: (userId) => ({
        url: `/${FACILITIES}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITIES, id: arg.id },
      ],
    }),
    archiveFacility: builder.mutation({
      query: (data) => ({
        url: `/${FACILITIES}/archive?facilityId=${data.facilityId}&archive=${data.archive}`,
        method: "PUT",
      }),
      invalidatesTags: (result) => [{ type: FACILITIES, id: result.id }],
    }),
    getFacilityLogo: builder.query({
      query: (facilityId) => `/${FACILITIES}/logo/${facilityId}`,
      transformResponse: (response) => response?.data,
    }),
    addFacilityLogo: builder.mutation({
      query: (data) => ({
        url: `/${FACILITIES}/logo`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: FACILITIES, id: result.id }],
    }),
    updateFacilityLogo: builder.mutation({
      query: (data) => ({
        url: `/${FACILITIES}/logo`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: FACILITIES, id: result.id }],
    }),
    deleteFacilityLogo: builder.mutation({
      query: (facilityId) => ({
        url: `/${FACILITIES}/logo/${facilityId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITIES, id: arg.id },
      ],
    }),
    customerProfileReport: builder.query({
      query: (facilityId) =>
        `${FACILITIES}/CustomerProfile?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITIES, id }],
    }),
    mealCardReport: builder.query({
      query: (facilityId) =>
        `${FACILITIES}/MealCardProfile?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITIES, id }],
    }),
    facilityDiets: builder.query({
      query: (facilityId) => `/${FACILITY_DIETS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITY_DIETS, id }],
      keepUnusedDataFor: 60,
    }),
    facilityVisits: builder.query({
      query: (facilityId) =>
        `${FACILITIES}/GetFacilityVisits?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, facilityId) => [
        { type: FACILITIES, facilityId },
      ],
    }),
    deleteFacilityDiet: builder.mutation({
      query: (id) => ({ url: `/${FACILITY_DIETS}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_DIETS, id: arg.id },
      ],
    }),
    copyToCorporateGroup: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_DIETS}/CopyToCorporateGroup?facilityId=${id}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_DIETS, id: arg.id },
      ],
    }),
    availableFacilityDiets: builder.query({
      query: (facilityId) =>
        `/${FACILITY_DIETS}/AvailableDiets?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
    }),
    availableFacilityDietSelectList: builder.query({
      query: (facilityId) =>
        `/${FACILITY_DIETS}/AvailableDiets?facilityId=${facilityId}`,
      transformResponse: (response) => {
        return response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        });
      },
    }),
    addFacilityDiet: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_DIETS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: FACILITY_DIETS, id: result.id }],
    }),
    updateFacilityDiet: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_DIETS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: FACILITY_DIETS, id: result.id }],
    }),
    facilityDietById: builder.query({
      query: (id) => `/${FACILITY_DIETS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITY_DIETS, id }],
    }),
    facilityContracts: builder.query({
      query: (facilityId) => `/${FACILITY_CONTRACTS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITY_CONTRACTS, id }],
    }),
    deleteFacilityContract: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_CONTRACTS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_CONTRACTS, id: arg.id },
      ],
    }),
    deleteFacilityAttachmentContract: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_CONTRACTS}/DeleteAttachment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_CONTRACTS, id: arg.id },
      ],
    }),
    facilityContractById: builder.query({
      query: (id) => `/${FACILITY_CONTRACTS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITY_CONTRACTS, id }],
    }),
    addFacilityContract: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_CONTRACTS}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_CONTRACTS, id: result.id },
      ],
    }),
    updateFacilityContract: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_CONTRACTS}`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_CONTRACTS, id: result.id },
      ],
    }),
    residentFacilityAdaptiveEquipments: builder.query({
      query: (facilityId) =>
        `${FACILITY_ADAPTIVE_EQUIPMENTS}?facilityId=${facilityId}`,
      transformResponse: (response) => {
        return response?.data?.facilityAdaptiveEquipments.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        });
      },
    }),
    facilityAdaptiveEquipments: builder.query({
      query: (facilityId) =>
        `${FACILITY_ADAPTIVE_EQUIPMENTS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: [FACILITY_ADAPTIVE_EQUIPMENTS],
    }),
    // Copy Adaptive Equipment to Corporate Group
    aeCopyToCorporateGroup: builder.mutation({
      query: (id) => ({
        url: `${FACILITY_ADAPTIVE_EQUIPMENTS}/CopyToCorporateGroup?facilityId=${id}`,
        method: "POST",
      }),
      invalidatesTags: [FACILITY_ADAPTIVE_EQUIPMENTS],
    }),
    getFacilityAdaptiveEquipmentsBulkEdit: builder.query({
      query: (facilityId) =>
        `${FACILITY_ADAPTIVE_EQUIPMENTS}/BulkEdit?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: [FACILITY_ADAPTIVE_EQUIPMENTS],
    }),
    facilityAdaptiveEquipmentsBulkEdit: builder.mutation({
      query: (data) => ({
        url: `${FACILITY_ADAPTIVE_EQUIPMENTS}/BulkEdit`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [FACILITY_ADAPTIVE_EQUIPMENTS],
    }),
    getFacilityIDDSIFlag: builder.query({
      query: (facilityId) =>
        `/${FACILITY_DIETS}/GetIDDSITerminologyFlag/${facilityId}`,
      transformResponse: (response) => {
        return response?.data.useIDDSITerminology;
      },
      keepUnusedDataFor: 10,
    }),
    updateFacilityDietsIDDSI: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_DIETS}/UpdateIDDSITerminologyFlag`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [{ type: FACILITIES, id: result.id }],
    }),
    getfacilityAssignedUsersList: builder.query({
      query: (facilityId) => `/${FACILITY_USERS}?facilityId=${facilityId}`,
      transformResponse: (response) => {
        return response?.data.users?.map((res) => {
          return {
            id: res.fullName,
            label: res.fullName,
          };
        });
      },
    }),
    facilityAssignedUsers: builder.query({
      query: (facilityId) => `/${FACILITY_USERS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITY_USERS, id }],
    }),
    deleteFacilityUser: builder.mutation({
      query: (id) => ({ url: `/${USER_FACILITIES}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_USERS, id: arg.id },
      ],
    }),
    addFacilityUser: builder.mutation({
      query: (data) => ({
        url: `/${USER_FACILITIES}?facilityId=${data.facilityId}&userId=${data.userId}`,
        method: "POST",
      }),
      invalidatesTags: (result) => [{ type: FACILITY_USERS, id: result.id }],
    }),
    facilityEHRs: builder.query({
      query: (facilityId) => `/${FACILITY_EHRS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response.ehRs
          ? [
              ...response.ehRs.map(({ id }) => ({ type: FACILITY_EHRS, id })),
              FACILITY_EHRS,
            ]
          : [FACILITY_EHRS],
    }),
    deleteFacilityEHR: builder.mutation({
      query: (id) => ({ url: `/${FACILITY_EHRS}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_EHRS, id: arg.id },
      ],
    }),
    addFacilityEHR: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_EHRS}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [{ type: FACILITY_EHRS }], // Use the same tag name as the query
    }),
    updateFacilityEHR: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_EHRS}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: FACILITY_EHRS, id: result.id }],
    }),
    facilityEHRById: builder.query({
      query: (id) => `/${FACILITY_EHRS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITY_EHRS, id }],
    }),
    facilityEHRDietMapping: builder.query({
      query: ({ facilityId, searchTerm }) =>
        `/${FACILITY_EHRS}/EHRDietMapping/GetEHRDietMappingByFacility?facilityId=${facilityId}&searchTerm=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_EHR_DIET_MAPPINGS,
                id,
              })),
              FACILITY_EHR_DIET_MAPPINGS,
            ]
          : [FACILITY_EHR_DIET_MAPPINGS];
      },
    }),
    facilityEHRDietMappingById: builder.query({
      query: (id) => `/${FACILITY_EHRS}/EHRDietMapping/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: FACILITY_EHR_DIET_MAPPINGS, id: response.id },
              FACILITY_EHR_DIET_MAPPINGS,
            ]
          : [FACILITY_EHR_DIET_MAPPINGS],
    }),
    deletefacilityEHRDietMapping: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_EHRS}/EHRDietMapping/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_EHR_DIET_MAPPINGS, id: arg.id },
      ],
    }),
    addfacilityEHRDietMapping: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_EHRS}/EHRDietMapping`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_EHR_DIET_MAPPINGS, id: result?.id },
      ],
    }),
    updatefacilityEHRDietMapping: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_EHRS}/EHRDietMapping`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_EHR_DIET_MAPPINGS, id: result?.id },
      ],
    }),
    facilityEHRLiquidMapping: builder.query({
      query: ({ facilityId, searchTerm }) =>
        `/${FACILITY_EHRS}/EHRLiquidMapping/GetEHRLiquidMappingByFacility?facilityId=${facilityId}&searchTerm=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_EHR_LIQUID_MAPPINGS,
                id,
              })),
              FACILITY_EHR_LIQUID_MAPPINGS,
            ]
          : [FACILITY_EHR_LIQUID_MAPPINGS];
      },
    }),
    facilityEHRLiquidMappingById: builder.query({
      query: (id) => `/${FACILITY_EHRS}/EHRLiquidMapping/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: FACILITY_EHR_LIQUID_MAPPINGS, id: response.id },
              FACILITY_EHR_LIQUID_MAPPINGS,
            ]
          : [FACILITY_EHR_LIQUID_MAPPINGS],
    }),
    deletefacilityEHRLiquidMapping: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_EHRS}/EHRLiquidMapping/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_EHR_LIQUID_MAPPINGS, id: arg.id },
      ],
    }),
    addfacilityEHRLiquidMapping: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_EHRS}/EHRLiquidMapping`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_EHR_LIQUID_MAPPINGS, id: result?.id },
      ],
    }),
    updatefacilityEHRLiquidMapping: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_EHRS}/EHRLiquidMapping`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_EHR_LIQUID_MAPPINGS, id: result?.id },
      ],
    }),
    facilityConsultingTypes: builder.query({
      query: (facilityId) =>
        `/${FACILITY_CONSULTINGS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_CONSULTINGS,
                id,
              })),
              FACILITY_CONSULTINGS,
            ]
          : [FACILITY_CONSULTINGS];
      },
    }),
    facilityConsultingTypesBox: builder.query({
      query: (facilityId) =>
        `/${FACILITY_CONSULTINGS}?facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.consultingTypeId,
            label: res.consultingTypeName,
          };
        }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_CONSULTINGS,
                id,
              })),
              FACILITY_CONSULTINGS,
            ]
          : [FACILITY_CONSULTINGS];
      },
    }),
    facilityConsultingTypeById: builder.query({
      query: (id) => `/${FACILITY_CONSULTINGS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: FACILITY_CONSULTINGS, id: response.id },
              FACILITY_CONSULTINGS,
            ]
          : [FACILITY_CONSULTINGS],
    }),
    deleteFacilityConsultingType: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_CONSULTINGS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_CONSULTINGS, id: arg.id },
      ],
    }),
    addFacilityConsultingType: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_CONSULTINGS}`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [
        { type: FACILITY_CONSULTINGS, id: result?.id },
      ],
    }),
    updateFacilityConsultingType: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_CONSULTINGS}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [
        { type: FACILITY_CONSULTINGS, id: result?.id },
      ],
    }),
    //community facilty export
    addFacilityExportPBJ: builder.mutation({
      query: (data) => ({
        url: `/${FACILITIES}/PBJExport?facilityId=${data.facilityId}&reportStartDate=${data.reportStartDate}&reportEndDate=${data.reportEndDate}`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.blob(),
        cache: "no-cache",
      }),
    }),
    addFacilityExportPBJExcel: builder.mutation({
      query: (data) => ({
        url: `/${FACILITIES}/PBJExcelExport?facilityId=${data.facilityId}&reportStartDate=${data.reportStartDate}&reportEndDate=${data.reportEndDate}`,
        method: "POST",
        body: data,
        cache: "no-cache",
      }),
    }),
    //community liquid consistency
    getFacilityThickenedLiquid: builder.query({
      query: (facilityId) =>
        `/${FACILITY_THICKENED_LIQUIDS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data?.thickenedLiquids,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_THICKENED_LIQUIDS,
                id,
              })),
              FACILITY_THICKENED_LIQUIDS,
            ]
          : [FACILITY_THICKENED_LIQUIDS];
      },
    }),
    getFacilityThickenedLiquidBulkEdit: builder.query({
      query: (facilityId) =>
        `/${FACILITY_THICKENED_LIQUIDS}/BulkEdit?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data?.thickenedLiquids,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_THICKENED_LIQUIDS,
                id,
              })),
              FACILITY_THICKENED_LIQUIDS,
            ]
          : [FACILITY_THICKENED_LIQUIDS];
      },
    }),
    AddThickenedLiquidBulkEdit: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_THICKENED_LIQUIDS}/BulkEdit`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_THICKENED_LIQUIDS, id: arg.id },
      ],
    }),
    AddcopyToThickenedLiquid: builder.mutation({
      query: ({ facilityId }) => ({
        url: `/${FACILITY_THICKENED_LIQUIDS}/CopyToCorporateGroup?facilityId=${facilityId}&exportPurchaseJournal=true`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_THICKENED_LIQUIDS, id: arg.id },
      ],
    }),
    //community Tableside setup
    getFacilityTableSide: builder.query({
      query: (facilityId) => `/${FACILITY_TABLESIDES}/${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: [FACILITY_TABLESIDES],
    }),
    updateFacilityTableSide: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_TABLESIDES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_TABLESIDES, id: result?.id },
      ],
    }),
    //community Custom Menu
    getFacilityCustomMenu: builder.query({
      query: (facilityId) => `/${FACILITY_CUSTOM_MENUS}/${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: [FACILITY_CUSTOM_MENUS],
    }),
    updateFacilityCustomMenu: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_CUSTOM_MENUS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_CUSTOM_MENUS, id: result?.id },
      ],
    }),

    //community Communication
    getCommunicationList: builder.query({
      query: (facilityId) =>
        `/${FACILITY_COMMUNICATIONS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_COMMUNICATIONS,
                id,
              })),
              FACILITY_COMMUNICATIONS,
            ]
          : [FACILITY_COMMUNICATIONS];
      },
    }),
    getCommunicationById: builder.query({
      query: (id) => `/${FACILITY_COMMUNICATIONS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [
        { type: FACILITY_COMMUNICATIONS, id },
      ],
    }),
    addCommunication: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_COMMUNICATIONS}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_COMMUNICATIONS, id: result.id },
      ],
    }),
    updateCommunication: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_COMMUNICATIONS}`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_COMMUNICATIONS, id: result.id },
      ],
    }),
    deleteCommunication: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_COMMUNICATIONS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_COMMUNICATIONS, id: arg.id },
      ],
    }),
    updateFacilityTableSideAutomation: builder.mutation({
      query: (data) => ({
        url: `/${TABLESIDE_AUTOMATIONS}?facilityId=${data.facilityId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [TABLESIDE_AUTOMATIONS],
    }),
    getFacilityTableSideAutomation: builder.query({
      query: (facilityId) => ({
        url: `/${TABLESIDE_AUTOMATIONS}?facilityId=${facilityId}`,
      }),
      providesTags: [TABLESIDE_AUTOMATIONS],
      transformResponse: (response) => response?.data,
    }),
    //community care types
    getFacilityCareType: builder.query({
      query: (facilityId) => `/${FACILITY_CARE_TYPES}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_CARE_TYPES,
                id,
              })),
              FACILITY_CARE_TYPES,
            ]
          : [FACILITY_CARE_TYPES];
      },
    }),
    getFacilityCareTypeBulkEdit: builder.query({
      query: (facilityId) =>
        `/${FACILITY_CARE_TYPES}/BulkEdit?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data?.careTypes,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_CARE_TYPES,
                id,
              })),
              FACILITY_CARE_TYPES,
            ]
          : [FACILITY_CARE_TYPES];
      },
    }),
    AddFacilityCareTypedBulkEdit: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_CARE_TYPES}/BulkEdit`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_CARE_TYPES, id: arg.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetFacilityListsQuery,
  useGetFacilityByNameQuery,
  useGetFacilityByUserFacilitiesQuery,
  useGetFacilitySelectBoxQuery,
  useSearchFacilitiesQuery,
  useGetFacilityByIdQuery,
  useSetAsDefaultFacilityMutation,
  useAddFacilityMutation,
  useUpdateFacilityMutation,
  useArchiveFacilityMutation,
  useGetFacilityLogoQuery,
  useAddFacilityLogoMutation,
  useUpdateFacilityLogoMutation,
  useDeleteFacilityLogoMutation,
  useDeleteFacilityMutation,
  useCustomerProfileReportQuery,
  useMealCardReportQuery,
  useFacilityDietsQuery,
  useFacilityVisitsQuery,
  useAvailableFacilityDietsQuery,
  useAvailableFacilityDietSelectListQuery,
  useDeleteFacilityDietMutation,
  useCopyToCorporateGroupMutation,
  useAddFacilityDietMutation,
  useUpdateFacilityDietMutation,
  useFacilityDietByIdQuery,
  useFacilityContractsQuery,
  useDeleteFacilityContractMutation,
  useDeleteFacilityAttachmentContractMutation,
  useFacilityContractByIdQuery,
  useAddFacilityContractMutation,
  useUpdateFacilityContractMutation,
  useResidentFacilityAdaptiveEquipmentsQuery,
  useFacilityAdaptiveEquipmentsQuery,
  useAeCopyToCorporateGroupMutation,
  useGetFacilityAdaptiveEquipmentsBulkEditQuery,
  useFacilityAdaptiveEquipmentsBulkEditMutation,
  useGetFacilityIDDSIFlagQuery,
  useUpdateFacilityDietsIDDSIMutation,
  useFacilityAssignedUsersQuery,
  useDeleteFacilityUserMutation,
  useAddFacilityUserMutation,
  useFacilityEHRsQuery,
  useDeleteFacilityEHRMutation,
  useAddFacilityEHRMutation,
  useUpdateFacilityEHRMutation,
  useFacilityEHRByIdQuery,
  useFacilityEHRDietMappingQuery,
  useFacilityEHRDietMappingByIdQuery,
  useAddfacilityEHRDietMappingMutation,
  useUpdatefacilityEHRDietMappingMutation,
  useDeletefacilityEHRDietMappingMutation,
  useFacilityEHRLiquidMappingQuery,
  useFacilityEHRLiquidMappingByIdQuery,
  useAddfacilityEHRLiquidMappingMutation,
  useUpdatefacilityEHRLiquidMappingMutation,
  useDeletefacilityEHRLiquidMappingMutation,
  useFacilityConsultingTypesQuery,
  useFacilityConsultingTypeByIdQuery,
  useAddFacilityConsultingTypeMutation,
  useUpdateFacilityConsultingTypeMutation,
  useDeleteFacilityConsultingTypeMutation,
  useAddFacilityExportPBJMutation,
  useGetFacilityThickenedLiquidQuery,
  useGetFacilityThickenedLiquidBulkEditQuery,
  useAddThickenedLiquidBulkEditMutation,
  useAddcopyToThickenedLiquidMutation,
  useGetFacilityTableSideQuery,
  useUpdateFacilityTableSideMutation,
  useGetFacilityCustomMenuQuery,
  useUpdateFacilityCustomMenuMutation,
  useGetCommunicationListQuery,
  useUpdateFacilityTableSideAutomationMutation,
  useGetFacilityTableSideAutomationQuery,
  useGetFacilityCareTypeQuery,
  useGetFacilityCareTypeBulkEditQuery,
  useGetCommunicationByIdQuery,
  useAddCommunicationMutation,
  useUpdateCommunicationMutation,
  useDeleteCommunicationMutation,
  useGetfacilityAssignedUsersListQuery,
  useAddFacilityCareTypedBulkEditMutation,
  useAddFacilityExportPBJExcelMutation,
  useFacilityConsultingTypesBoxQuery,
} = FacilityAPIs;
