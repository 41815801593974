import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { theme } from "styles/theme";
import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";

const colorPalette = theme.palette;
const colorOptions = Object.entries(colorPalette)
  .filter((x) => x[0] !== "mode" && x[1]?.main)
  .map((color) => {
    return {
      id: color[0],
      label: color[0]?.charAt(0).toUpperCase() + color[0]?.slice(1),
      description: color[1]?.main,
    };
  });

export const VISIT_REPORT_TYPE_FORM = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Report Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Report Name"),
      maxLength: MAX_LENGTH_VALIDATION("Report Name", 40),
    },
    size: 6,
  },
  {
    props: {
      name: "sortOrder",
      id: "sortOrder",
      label: "Sort Order",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
    },
    size: 6,
  },

  {
    props: {
      name: "isVisibleToConsultant",
      id: "isVisibleToConsultant",
      label: "Is Visible to Consultant",
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },

  {
    props: {
      name: "hasResidents",
      id: "hasResidents",
      label: "Has Residents?",
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },

  {
    props: {
      name: "color",
      id: "color",
      label: "Color",
    },
    size: 3,
    type: FORM_TYPES.Select,
    options: [...colorOptions],
  },
];

export const VISIT_REPORT_TYPE_DEFAULTS = {
  id: -1,
  name: "",
  sortOrder: 1,
};
