import React from "react";
import { useGetResidentNotesQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../shared/YesNoDisplay";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function ResidentNotes() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentNotes,
    isLoading,
    isError,
  } = useGetResidentNotesQuery(residentId, { skip: !residentId });

  const noteColumns = [
    {
      field: "note",
      minWidth: 50,
      flex: 2,
      headerName: "Note",
    },
    {
      field: "noteType",
      minWidth: 50,
      flex: 1,
      headerName: "Type",
    },
    {
      field: "includeOnTrayCard",
      flex: 1,
      headerName: "Meal Card",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box alignItems="center">
            <YesNoDisplay value={params?.row?.includeOnTrayCard} />
          </Box>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentNotes}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addresidentNote?noteId=${params.row.id}&id=${residentId}`,
              title: "Resident Note",
            }}
            deleteProps={{
              entityName: params.row?.noteType,
              apiPath: "deleteResidentNote",
              title: "Resident Note",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          gap: 1,
        }}>
        <Box>
          <BaseButton
            text={"Add Note"}
            endIcon={"post_add"}
            To={`addresidentNote?id=${residentId}`}
            marginLeft="0px !important"
            sx={{
              marginY: "0px !important",
            }}
          />
        </Box>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={residentNotes}
            columns={CreateBaseColumns(noteColumns)}
            error={isError}
            loading={isLoading}
            height={"100%"}
          />
        </Box>
      </Box>
    </>
  );
}
