import React, { useState, useEffect } from "react";
import { useGetCorporateDocumentsQuery } from "../../../../../../store/apis/CorporateGroupDocumentApis";
import { Button, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import { Assignment, KeyboardArrowLeft } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function CorporateDocument() {
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const currentId = searchParams.get("id");

  const [documentContainer, setDocumentContainer] = useState(null);

  const {
    data: corporateGroups,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
    isSuccess: corporateGroupsSuccess,
  } = useGetCorporateDocumentsQuery(
    { corporateGroupId: currentId, searchText: debouncedSearchValue || "" },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (corporateGroupsSuccess && corporateGroups) {
      setDocumentContainer(corporateGroups?.containerName);
    }
  }, [corporateGroupsSuccess]);

  const corporateGroupColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "groupName",
      minWidth: 100,
      flex: 2,
      headerName: "Document Group",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "sortOrder",
      minWidth: 100,
      flex: 1,
      headerName: "Sort Order",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "url",
      minWidth: 100,
      flex: 1,
      headerName: "URL",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Link
            target={"_blank"}
            rel="noreferrer"
            to={params.row?.url}
            style={{ textDecoration: "none", color: "red" }}>
            {"document"}
          </Link>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={corporateGroups?.documents}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addDocument?corporateId=${currentId}&&id=${params.row.id}`,
              title: "Document",
            }}
            deleteProps={{
              entityName: params.row?.name,
              apiPath: "deleteCorporateDocuments",
              title: "Document",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={1}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <Button
          component={Link}
          to={"/admin/communities/corporateGroups"}
          color={"primary"}
          variant="contained"
          startIcon={<KeyboardArrowLeft />}>
          {"Back to Corporate Group  List"}
        </Button>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedSearchValue}
          setSearchParams={setSearchParams}
          isId={true}
          Id={currentId}
        />
        {documentContainer ? (
          <Button
            component={Link}
            to={`addDocument?corporateId=${currentId}`}
            color={"primary"}
            variant="contained"
            state={{ container: documentContainer }}
            endIcon={<Assignment />}>
            Add Document
          </Button>
        ) : (
          <Typography
            variant="caption"
            sx={{
              color: "error",
            }}>
            Please contact the Administrator to setup the document container for
            this corporate group.
          </Typography>
        )}
      </FlexBox>
      <BaseDataGrid
        rows={corporateGroups?.documents}
        columns={corporateGroupColumns}
        loading={corporateGroupsLoading}
        error={corporateGroupsError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
