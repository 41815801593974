import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Divider,
  Skeleton,
  Typography,
  Grid,
} from "@mui/material";
import { useUserFacilitiesQuery } from "../../store/apis/UserApis";
import { useSetAsDefaultFacilityMutation } from "../../store/apis/FacilityApis";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFacilityId,
  setFacilityId,
} from "../../store/slices/authSlice/AuthSlice";
import { BaseApi } from "store/apis/BaseApi";
import useMsalAccount from "utils/useMsalAccount";

export const SelectCommunity = ({ handleClose }) => {
  const { userId: currentUserId } = useMsalAccount();
  const currentFacilityId = useSelector(selectFacilityId);
  const dispatch = useDispatch();

  const [selectedFacilityId, setSelectedFacilityId] =
    useState(currentFacilityId);

  const {
    data: userFacilities,
    isFetching: loadingUserFacilities,
    isSuccess: userFacilitiesSuccess,
  } = useUserFacilitiesQuery(currentUserId, { skip: !currentUserId });

  const [
    changeFacility,
    { isLoading: changeLoading, isSuccess: changeSuccess },
  ] = useSetAsDefaultFacilityMutation();

  useEffect(() => {
    if (changeSuccess && !changeLoading) {
      dispatch(setFacilityId(selectedFacilityId));
      dispatch(BaseApi.util.resetApiState());
      handleClose();
    }
  }, [changeSuccess, changeLoading]);

  const handleChange = (event) => {
    const facilityId = event.target.value;
    setSelectedFacilityId(facilityId);

    changeFacility({ userId: currentUserId, facilityId: facilityId });
  };

  return (
    <>
      {!loadingUserFacilities &&
      userFacilitiesSuccess &&
      userFacilities?.length >= 1 ? (
        <div>
          <Divider />
          <div className="menuLabel">Select Community:</div>
          <Select
            id="community-select"
            value={selectedFacilityId}
            onChange={handleChange}
            size="small">
            {[...userFacilities]
              .sort((a, b) => a.facilityName.localeCompare(b.facilityName))
              ?.map((option) => (
                <MenuItem key={option.facilityId} value={option.facilityId}>
                  <Grid container direction={"column"}>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 22px)",
                        wordWrap: "break-word",
                      }}>
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: "bold",
                        }}>
                        {option?.facilityName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.secondary",
                        }}>
                        {`${option?.corporateGroupName || ""} - ${
                          option?.city
                        } / ${option?.state}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
          </Select>
        </div>
      ) : loadingUserFacilities ? (
        <div>
          <Divider />
          <div className="menuLabel">Select Community:</div>
          <Skeleton variant="rectangular" width={300} height={40} />
        </div>
      ) : undefined}
    </>
  );
};
