import { REQUIRED_ERROR } from "./errorMessages";
import { oneToThreeDigitPositiveNumber } from "./ValidationRegex";

export const startingDayValidation = () => ({
  required: REQUIRED_ERROR("Starting Day"),
  pattern: {
    value: oneToThreeDigitPositiveNumber,
    message: "Please enter positive number less than 1000",
  },
  min: { value: 1, message: "Please enter positive number" },
  max: { value: 999, message: "Please enter positive number less than 1000" },
});

export const endingDayValidation = () => ({
  required: REQUIRED_ERROR("Ending Day"),
  pattern: {
    value: oneToThreeDigitPositiveNumber,
    message: "Please enter positive number less than 1000",
  },
  min: { value: 1, message: "Please enter positive number" },
  max: { value: 999, message: "Please enter positive number less than 1000" },
  // validate: (value, formValues) =>
  //   parseInt(value) < parseInt(formValues.startingDay)
  //     ? "Ending day should not be less than the starting day"
  //     : null,
});
