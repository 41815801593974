import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
import { positiveDecimalInteger } from "utils/ValidationRegex";
export const RecipeIngredientsFields = [
  {
    title: "Ingredient",
    props: {
      name: "ingredientId",
      id: "ingredientId",
      placeholder: "Select an ingredient",
      // label: "Ingredient",
    },
    validationProps: {
      required: REQUIRED_ERROR("Ingredient"),
    },
    size: 8,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getRecipeIngredientsName",
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
  {
    title: "Quantity 1",
    props: {
      name: "quantity",
      id: "quantity",
      // label: "Quantity 1",
      type: "number",
      title: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("Quantity 1"),
      min: { value: 0.1, message: "Value must be greater than 0" },
      pattern: {
        value: positiveDecimalInteger,
        message: "Invalid number format",
      },
    },

    size: 3,
    step: "any",
  },
  {
    title: "Unit 1",
    props: {
      name: "unitId",
      id: "unitId",
      placeholder: "Select a unit",
      // label: "Unit 1",
    },
    validationProps: {
      required: REQUIRED_ERROR("Unit 1"),
    },
    size: 5,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getRecipeIngredientUnits",
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
  {
    title: "Quantity 2",
    props: {
      name: "quantity2",
      id: "quantity2",
      // label: "Quantity 2",
      type: "number",
      title: "",
      defaultValue: 0,
    },
    validationProps: {
      min: { value: 0, message: "Value must be equal or greater than 0 " },
    },
    size: 3,
    step: "any",
  },
  {
    title: "Unit 2",
    props: {
      name: "unitId2",
      id: "unitId2",
      placeholder: "Select a unit",
      // label: "Unit 2",
    },
    size: 5,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getRecipeIngredientUnits",
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
  {
    title: "Preparation Note",
    props: {
      name: "description",
      id: "description",
      // label: "Preparation",
    },
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Preparation Note", 100),
    },
    size: 8,
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
  {
    title: "Sort",
    props: {
      name: "sortOrder",
      id: "sortOrder",
      // label: "Sort",
      type: "number",
      defaultValue: 0,
    },
    validationProps: {
      min: { value: 0, message: "Value must be equal or greater than 0 " },
      max: { value: 99999, message: "Value must be 5 digits or fewer" },
    },
    size: 8,
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
  {
    title: "Percentage Yield",
    props: {
      name: "percentToIncludeInAnalysis",
      id: "percentToIncludeInAnalysis",
      // label: "% to include",
      type: "number",
      defaultValue: 100,
    },
    validationProps: {
      min: { value: 0, message: "Value must be equal or greater than 0 " },
      max: { value: 99999, message: "Value must be 5 digits or fewer" },
    },
    size: 6,
  },
  {
    props: {
      name: "addSpaceAfter",
      id: "addSpaceAfter",
      label: "Include Space After Ingredient",
      defaultValue: false,
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
];
