import React from "react";
import { SECTION_DEFAULTS, SECTION_FORM } from "./forms.constants";
import FieldGroup from "./FieldGroup";
import GenericFieldForm from "./GenericFieldForm";

export default function Section({ nestIndex }) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${nestIndex}].sections`}
      fieldList={SECTION_FORM}
      appendObjectExample={SECTION_DEFAULTS}
      nameOfObject={"Section"}
      fieldPropName={`sectionGroups[${nestIndex}].sections`}
      ChildComponent={FieldGroup}
      childProps={{ sectionIndex: nestIndex }}
      isSortOrder={false}></GenericFieldForm>
  );
}
