import { useDrop } from "react-dnd";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/useCustomDrop.md)
 */
export const useCustomDrop = (accept, statusChange, checkCanDrop = () => { return true; }) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: (item, monitor) => statusChange(item, monitor),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: (item) => checkCanDrop(item),
  });
  return { isOver, drop };
};
