import { Typography } from "@mui/material";
import React from "react";
import { DMStyles } from "../../styles/Styles";

export const WarningText = React.forwardRef(
  ({ text, color, width, fontSize, padding, margin, sx }, ref) => {
    const colors =
      color === "warning"
        ? DMStyles.DarkYellow
        : color === "red"
        ? DMStyles.Red
        : color === "black"
        ? DMStyles.Black
        : DMStyles.Teal;

    return (
      <>
        <Typography
          // Forward the ref to the Typography component
          ref={ref}
          variant="p"
          component={"p"}
          sx={{
            fontWeight: 600,
            color: colors,
            border: `1px solid ${color === "black" ? "gray" : colors}`,
            padding: padding || "10px",
            margin: margin || "20px !important",
            borderRadius: "10px",
            width: width,
            fontSize: fontSize,
            bgcolor: color === "warning" ? "rgba(222, 109, 0, 0.1)" : "",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
            ...sx,
          }}>
          {text}
        </Typography>
      </>
    );
  }
);

WarningText.displayName = "WarningText";
