import React from "react";
import Field from "./Field";
import { Box, Grid2, Typography } from "@mui/material";

export default function FieldGroup({ fieldGroup }) {
  if (fieldGroup?.name !== "-") {
    return (
      <Box width={"100%"}>
        <Typography fontWeight={"800"}>{fieldGroup?.name}</Typography>

        <Box p={1}>
          <Grid2 container>
            {fieldGroup?.fields?.map((field) => (
              <Field key={field.id} field={field} />
            ))}
          </Grid2>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {fieldGroup?.fields?.map((field) => (
        <Field key={field.id} field={field} />
      ))}
    </Box>
  );
}
