import React from "react";
import { FIELD_DEFAULTS, FIELD_FORM } from "./forms.constants";
import FieldOption from "./FieldOption";
import GenericFieldForm from "./GenericFieldForm";

export default function Field({ nestIndex, sectionIndex, fieldGroupIndex }) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${nestIndex}].fields`}
      fieldList={FIELD_FORM}
      appendObjectExample={FIELD_DEFAULTS}
      nameOfObject={"Field"}
      fieldPropName={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${nestIndex}].fields`}
      ChildComponent={FieldOption}
      childProps={{
        fieldGroupIndex,
        fieldIndex: nestIndex,
        sectionIndex,
      }}
      isSortOrder={false}></GenericFieldForm>
  );
}
