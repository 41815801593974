import React from "react";
import Field from "./Field";
import { FIELD_GROUP_DEFAULTS, FIELD_GROUP_FORM } from "./forms.constants";
import GenericFieldForm from "./GenericFieldForm";

export default function FieldGroup({ nestIndex, sectionIndex }) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${sectionIndex}].sections[${nestIndex}].fieldGroups`}
      fieldList={FIELD_GROUP_FORM}
      appendObjectExample={FIELD_GROUP_DEFAULTS}
      nameOfObject={"Field Group"}
      fieldPropName={`sectionGroups[${sectionIndex}].sections[${nestIndex}].fieldGroups`}
      ChildComponent={Field}
      childProps={{
        fieldGroupIndex: nestIndex,
        sectionIndex: sectionIndex,
      }}
      isSortOrder={true}></GenericFieldForm>
  );
}
