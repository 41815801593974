import { Box } from "@mui/material";
import React from "react";
import { useDragLayer } from "react-dnd";
import { categoryColorStrips } from "./constants";

//Core layer styles, item styles set in template below
const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 9999, // needs to show above 9990 in MealViewModal
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

const getFieldStyle = (isDragging) => {
  const style = {
    width: 250,
    maxWidth: 250,
    backgroundColor: "white",
    fontSize: "1rem",
    minHeight: 21
  };
  style.opacity = isDragging ? 0.8 : 1;
  return style;
};

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: "none"
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform
  };
};

/* 
 * Drag Layer holds the logic to handle the drag preview for the selection
 * Browser default just picks up the DOM element under the cursor with lower opacity 
 * but that is not sufficient for multi-select.
*/
export default function RecipeCardDragLayer() {
  const { itemType, items, isDragging, currentOffset } = useDragLayer(
    (monitor) => ({
      items: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      clientOffset: monitor.getClientOffset()
    })
  );

  const renderItem = (type, items) => {
    switch (type) {
      case "card": 
      case "delete":
        return <RecipeCardDragTemplate items={items} />
      default:
        return null;
    }
  }

  if (!isDragging) { return null; }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset)}>
        <div style={getFieldStyle(isDragging)}>
          {renderItem(itemType, items)}
        </div>
      </div>
    </div>
  );
}

/*
 * Drag preview template for selected list
*/
export function RecipeCardDragTemplate({ items }) {
  const categoryColor = (item) => {
    return categoryColorStrips.find((cat) => cat.id === item?.finalData?.menuCategoryId);
  };

  const renderIcons = (item) => {
    const iconList = [];

    if (item?.finalData?.fr) {
      iconList.push({color: "#00bcbe" , icon: "house"});
    } 
    if (item?.finalData?.isBaseRecipe) {
      iconList.push({color: "#00bcbe" , icon: "key"});
    } 
    if (item?.finalData?.isSubstituteRecipe) {
      iconList.push({color: "#00bcbe" , icon: "star"});
    } 
    if (item?.finalData?.substituteRecipeId > 0) {
      iconList.push({color: "red" , icon: "upload"});
    }

    return iconList.map((icon, idx) => (<span key={idx} className="material-icons" style={{color: icon.color, fontSize: "1rem", lineHeight: "1.25rem", textAlign: "center"}}>{icon.icon}</span>));
  };

  const rows = items.map((item, idx) => (
      <Box sx={{marginY: ".25rem", display: "flex", alignItems: "center", justifyContent: "flex-start"}} key={`${item?.finalData?.recipeId}-${idx}`}>
        {renderIcons(item)}
        {item.finalData?.recipeName}
        <span style={{height: "100%", width: "3px", backgroundColor: `${categoryColor(item)?.color}`, marginLeft: "auto"}}>&nbsp;</span>
      </Box>
  ));

  return ( <>{rows}</> )
}