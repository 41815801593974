import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeaderText } from "store/slices/appSlice/AppSlice";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  // useTheme,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import { DMStyles } from "styles/Styles";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { Link, useSearchParams } from "react-router-dom";
import {
  useChangeFacilityContactMutation,
  useGetFacilityDetailsQuery,
  useGetPastVisitsByFacilityQuery,
  useGetUpcomingVisitsByFacilityQuery,
} from "store/apis/ClientPortalAPIs";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { DownloadLinkHelper } from "components/shared/utils/helpers";
import ArrowPopover from "./ArrowPopover";
import BaseInput from "components/shared/baseForm/BaseInput";
import { FORM_FIELDS } from "./form.constants";
import { useForm } from "react-hook-form";
import { BaseButton } from "components/shared/BaseButton";
import BaseBanner from "components/shared/baseBanner/BaseBanner";
import { useGetVisitReportTypesQuery } from "store/apis/VisitReportAPIs";
import BaseReportChip from "components/shared/BaseReportChip";
import ChipButton from "components/shared/styledComponents/ChipButton";
import FlexBox from "components/shared/styledComponents/FlexBox";
import "./FacilityPortal.css";

export default function FacilityPortal() {
  // const theme = useTheme();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { data: reportTypeData, isLoading: reportTypeLoading } =
    useGetVisitReportTypesQuery();

  const INDIVIDUAL = "Individual";

  const handleClick = (event, params) => {
    setPopoverContent(params);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { data: facilityDetails, isLoading: facilityLoading } =
    useGetFacilityDetailsQuery(
      { facilityId: facilityId },
      { skip: !facilityId }
    );

  const { data: pastVisits, isLoading: pastLoading } =
    useGetPastVisitsByFacilityQuery(
      { facilityId: facilityId },
      { skip: !facilityId }
    );

  const { data: futureVisits, isLoading: futureLoading } =
    useGetUpcomingVisitsByFacilityQuery(
      { facilityId: facilityId },
      { skip: !facilityId }
    );

  const [updateFacilityContact, { isLoading: isSubmitting, isSuccess }] =
    useChangeFacilityContactMutation();

  function submitForm(data) {
    data.id = facilityId;
    updateFacilityContact(data);
  }

  useEffect(() => {
    if (isSuccess) {
      setIsEdit(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (facilityLoading)
      // Set the header text with Facility's name
      dispatch(setHeaderText("Facility Portal"));
    else dispatch(setHeaderText(`${facilityDetails?.name}'s Portal`));
  }, [facilityLoading]);

  const communityDetails = useMemo(() => {
    if (facilityDetails) {
      const details = [
        { name: "Address", value: facilityDetails?.address },
        { name: "Phone Number", value: facilityDetails?.phoneNumber },
        { name: "Corporate Group", value: facilityDetails?.corporateGroup },
        {
          name: "Assigned Consultant",
          value: facilityDetails?.assignedConsultant,
        },
        {
          name: "Regional Consultant",
          value: facilityDetails?.regionalConsultant,
        },
      ];
      if (facilityDetails?.corporateGroup == INDIVIDUAL) {
        return details.filter((x) => x.name !== "Corporate Group");
      }
      return details;
    }
    return [
      { name: "Address", value: "-" },
      { name: "Phone Number", value: "-" },
      { name: "Assigned Consultant", value: "-" },
      { name: "Regional Consultant", value: "-" },
    ];
  }, [facilityDetails]);

  const communityContact = useMemo(() => {
    if (facilityDetails) {
      return [
        {
          name: "Admin",
          field: "administratorEmail",
          value: facilityDetails?.administratorEmail,
        },
        { name: "DON", field: "don", value: facilityDetails?.don },
        {
          name: "MDS Coord",
          field: "mdsCoord",
          value: facilityDetails?.mdsCoord,
        },
        {
          name: "FSD",
          field: "fssDsdemail",
          value: facilityDetails?.fssDsdemail,
        },
      ];
    }
    return [
      { name: "Admin", field: "administratorEmail", value: "-" },
      { name: "DON", field: "don", value: "-" },
      { name: "MDS Coord", field: "mdsCoord", value: "-" },
      { name: "FSD", field: "fssDsdemail", value: "-" },
    ];
  }, [facilityDetails]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: communityContact.reduce((acc, item) => {
      acc[item.field] = item.value;
      return acc;
    }, {}),
  });
  // destructing of useform
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset(
      communityContact.reduce((acc, item) => {
        acc[item.field] = item.value;
        return acc;
      }, {})
    );
  }, [communityContact]);

  const upcomingVisitColumns = [
    {
      field: "dateOfVisit",
      headerName: "Date",
      valueGetter: (value) => {
        return value ? dayjs(value).toDate() : "";
      },
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
      flex: 1,
    },
    {
      field: "dietitianName",
      headerName: "Consultant",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Link variant="p" onClick={(event) => handleClick(event, params)}>
          {params.row.dietitianName}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "visits",
      headerName: "Reports",
      headerAlign: "center",
      align: "center",
      renderCell: () => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}>
          <Typography variant="h6">Incomplete/Pending Visit</Typography>
        </Box>
      ),
      flex: 4,
    },
  ];

  const pastVisitColumns = [
    {
      field: "dateOfVisit",
      headerName: "Date",
      valueGetter: (value) => {
        return value ? dayjs(value).toDate() : "";
      },
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
      flex: 1,
    },
    {
      field: "dietitianName",
      headerName: "Consultant",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Link variant="p" onClick={(event) => handleClick(event, params)}>
          {params.row.dietitianName}
        </Link>
      ),
      flex: 2,
    },
    {
      field: "hours",
      headerName: "Hours/Mileage",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}>
          <Typography variant="p">{`${params.row.hours} hrs/${params.row.mileage} mi`}</Typography>
        </Box>
      ),
      flex: 1,
    },
    {
      field: "portalDocuments",
      headerName: "Reports",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const mergedReport = params?.row?.portalDocuments?.find(
          (x) => x.portalDocumentTypeId == 7
        );

        const nonMergedReports = params?.row?.portalDocuments?.filter(
          (x) => x.portalDocumentTypeId <= 6
        );

        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent:
                nonMergedReports.length === 0 ? "center" : "space-between",
            }}>
            {reportTypeLoading ? (
              <Skeleton />
            ) : (
              <>
                {nonMergedReports.length === 0 ? (
                  <Typography align="center">No Reports Available</Typography>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}>
                      {nonMergedReports.map((item) => {
                        return (
                          <BaseReportChip
                            key={item?.id}
                            id={item?.id}
                            typeId={item?.portalDocumentTypeId}
                            onClick={() => {
                              DownloadLinkHelper(item?.urlPath);
                            }}
                            reportTypes={reportTypeData}
                          />
                        );
                      })}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {mergedReport ? (
                        <ChipButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            DownloadLinkHelper(mergedReport?.urlPath);
                          }}
                          label={"Print All"}
                        />
                      ) : (
                        <ChipButton
                          size="small"
                          color="gray"
                          label={"N/A"}></ChipButton>
                      )}
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        );
      },
      flex: 5,
    },
  ];

  return (
    <>
      {/* Community Header */}
      <BaseBanner
        title={facilityDetails?.name}
        fontWeight={800}
        color={DMStyles.White}
        // bgColor={theme.palette.primary.main}
        bgColor={"#1A4650"}
        marginBottom="0px"
        titleProps={{ variant: "h4" }}
        loading={facilityLoading}
        subTitleLoading={futureLoading}
        subTitle={
          futureLoading
            ? undefined
            : `Next Visit Consultant: ${
                futureVisits[0]?.dietitianName ?? "N/A"
              }`
        }
      />
      {/* Community Boxes */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "16px",
          justifyContent: "center",
          // color: theme.palette.black.main,
          alignItems: "center",
        }}>
        {/* Community Details */}
        <Card sx={{ width: "400px", marginRight: "8px" }}>
          <CardHeader
            title="Community Details"
            titleTypographyProps={{
              variant: "h6",
              fontStyle: "normal",
              fontSize: "1.1rem",
              fontWeight: "700",
              fontColor: "white",
            }}
            sx={{
              // boxShadow: DMStyles.TealBoxShadow,
              boxShadow: "rgba(0,0,0,0.25)",
              // backgroundColor: theme.palette.primary.light,
              backgroundColor: "#1A4650",
              color: "white",
              paddingY: "8px",
            }}></CardHeader>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            {communityDetails.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                    }}>
                    {item.name} :
                  </Typography>
                  {facilityLoading ? (
                    <Skeleton variant="text" width={100} height={20} />
                  ) : (
                    <Typography variant="p">{item.value}</Typography>
                  )}
                </Box>
              );
            })}
          </CardContent>
        </Card>
        {/* Community Contact */}
        <Card sx={{ width: "400px", height: "100%", marginLeft: "8px" }}>
          <CardHeader
            component={Box}
            disableTypography
            title={
              <Typography
                variant="h6"
                sx={{
                  fontStyle: "normal",
                  fontSize: "1.1rem",
                  fontWeight: "700",
                }}>
                Community Contact
              </Typography>
            }
            action={
              <FlexBox justifyContent={"end"}>
                {isEdit ? (
                  <>
                    <Button
                      color="white"
                      variant="contained"
                      size="small"
                      onClick={() => setIsEdit(!isEdit)}>
                      Cancel
                    </Button>
                    <BaseButton
                      colors="white"
                      variant="contained"
                      text={"Save"}
                      loadingColor={"black"}
                      size="small"
                      isSubmitting={isSubmitting}
                      onClick={handleSubmit(submitForm)}>
                      Save
                    </BaseButton>
                  </>
                ) : (
                  <Button
                    color="white"
                    variant="contained"
                    size="small"
                    onClick={() => setIsEdit(!isEdit)}>
                    Edit
                  </Button>
                )}
              </FlexBox>
            }
            sx={{
              boxShadow: DMStyles.TealBoxShadow,
              // backgroundColor: theme.palette.primary.light,
              backgroundColor: "#1A4650",
              paddingY: "8px",
            }}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            {communityContact.map((item, index) => {
              const field = FORM_FIELDS.find(
                (x) => x.props.labelprop === item.name
              );

              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: isEdit && "8px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                    }}>
                    {item.name} :
                  </Typography>
                  {facilityLoading ? (
                    <Skeleton variant="text" width={100} height={20} />
                  ) : (
                    <>
                      {isEdit ? (
                        <BaseInput
                          fullWidth={false}
                          size={"small"}
                          margin={"none"}
                          denser={true}
                          hiddenLabel={true}
                          width={"auto"}
                          control={control}
                          errors={errors}
                          validationProps={field.validationProps}
                          {...field.props}></BaseInput>
                      ) : (
                        <Typography variant="p">{item.value}</Typography>
                      )}
                    </>
                  )}
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Box>
      {/* Community Past Visits */}
      <Typography
        variant="h5"
        sx={{
          my: "16px",
          fontWeight: "600",
        }}>
        Completed Visits
      </Typography>
      <BaseDataGrid
        rows={pastVisits}
        columns={pastVisitColumns}
        height={"300px"}
        density={"standard"}
        loading={pastLoading}
        containerStyles={{ minHeight: "300px" }}
        getRowHeight={() => "auto"}
      />
      {/* Community Upcoming Visits */}
      <Typography
        variant="h5"
        sx={{
          my: "16px",
          fontWeight: "600",
        }}>
        Incomplete/Upcoming Visits
      </Typography>
      <BaseDataGrid
        rows={futureVisits}
        loading={futureLoading}
        columns={upcomingVisitColumns}
        containerStyles={{ minHeight: "300px" }}
        getRowHeight={() => "auto"}
        height={"300px"}
      />
      <ArrowPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        name={popoverContent?.row?.dietitianName}
        contact={popoverContent?.row?.contact}
        email={popoverContent?.row?.email}
        handleClose={handleClose}
        popoverContent={popoverContent}
      />
    </>
  );
}
