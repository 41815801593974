import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";

import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { useGetPagedVendorListQuery } from "../../../../../store/apis/VendorApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";
import BaseMenu from "components/shared/BaseMenu";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { CurrencyExchange, PersonAdd, UploadFile } from "@mui/icons-material";

export default function VendorSetup() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: vendors,
    isFetching: loading,
    isError: hasError,
  } = useGetPagedVendorListQuery({
    query: debouncedSearchValue || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const vendorColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "numberOfFacilities",
      headerName: "# of Facilities",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        if (params.row["numberOfFacilities"] === 0) {
          return params.row["numberOfFacilities"];
        } else
          return (
            <Link
              to={`/admin/communities/communitySetup?vendorId=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params.row["numberOfFacilities"]}
            </Link>
          );
      },
    },
    {
      field: "pricingUpdatedOn",
      headerName: "Last Pricing Upload",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => {
        if (null === value) {
          return "";
        } else {
          return new Date(value).toLocaleDateString();
        }
      },
    },
    {
      field: "pricingUpdatedBy",
      headerName: "Uploaded By",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={vendors?.items}
            hasEdit={true}
            editProps={{
              To: `addVendor?id=${params.row.id}`,
              title: "Vendors",
            }}
            childrenLocation={"end"}>
            <VendorOptions
              index={params.row.id}
              id={params.row.id}
              name={params.row["name"]}
              searchValue={searchValue}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        mb={"3px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <FlexBox justifyContent={"start"}>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedSearchValue}
            setSearchParams={setSearchParams}
            setPaginationModel={setPaginationModel}
          />
          <BaseButton
            To="addVendor"
            endIcon={<PersonAdd />}
            text={"Add Vendor"}
          />
        </FlexBox>
        <FlexBox justifyContent={"end"}>
          <BaseButton
            To="importVendorPrice"
            variant="contained"
            endIcon={<UploadFile />}
            text={"Import Vendor Price Spreadsheet"}
          />
          <BaseButton
            To="recalcVendorPrice"
            endIcon={<CurrencyExchange />}
            text={"Recalculate Pricing"}
          />
        </FlexBox>
      </FlexBox>

      <BaseDataGrid
        loading={loading}
        columns={vendorColumns}
        rowCount={vendors?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rows={vendors?.items || 0}
        error={hasError}
        autoHeight={false}
        overflow={"none"}
      />
    </>
  );
}

const VendorOptions = ({ index, id, name }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <BaseMenu index={index}>
        <MenuItem
          component={Link}
          to={`./VendorProducts?id=${id}`}
          state={{ vendorName: name }}
          disableRipple>
          Products
        </MenuItem>
        <MenuItem
          component={Link}
          to={`./VendorCopyProducts?id=${id}`}
          state={{ vendorName: name }}
          disableRipple>
          Copy Products From
        </MenuItem>
        <MenuItem
          component={Link}
          to={`./BulkProductEntry?id=${id}`}
          state={{ vendorName: name }}
          disableRipple>
          Bulk Product Entry
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModal(true);
          }}
          className="menuDeleteButton"
          disableRipple>
          Delete
        </MenuItem>
      </BaseMenu>
      {openModal === true ? (
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteVendor"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
