import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetFacilityTableSideQuery } from "../../../../../../store/apis/FacilityApis";
import { BaseButton } from "components/shared/BaseButton";
import { EditButton } from "components/shared/EditButton";
import { Edit, GppBad, VerifiedUser } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function CommunityTableSide() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data: tableSide } = useGetFacilityTableSideQuery(id, { skip: !id });

  const renderTableRow = (value, icon) => {
    return (
      <Grid className="tablesideRow">
        <Typography variant="p">{value}</Typography>
        {icon ? <VerifiedUser color="primary" /> : <GppBad color="error" />}
      </Grid>
    );
  };

  return (
    <>
      <FlexBox justifyContent={{ xs: "space-between", sm: "start" }} my={1}>
        <BaseButton
          text={"Automatic Start Setup"}
          endIcon={<Edit />}
          To={`automaticsetup?id=${id}`}
          colors={"error"}
        />
        <EditButton
          To={`edit?id=${id}`}
          state={{ data: tableSide }}
          // styles={{ bg: "#575757", color: "white" }}
        />
      </FlexBox>

      <div>
        {renderTableRow(
          "Declining Balance",
          tableSide?.tableSideDecliningBalance
        )}
        {renderTableRow("Direct Printing", tableSide?.tablesideDirectPrint)}
        {renderTableRow(
          "Items Selected by Default",
          tableSide?.tablesideAllItemsSelectedByDefault
        )}
        {renderTableRow(
          "Hide portion on ticket",
          tableSide?.tablesideTicketHidePortion
        )}
        {renderTableRow(
          "Print MealCard Notes",
          tableSide?.tablesidePrintMealCardNotes
        )}
        {renderTableRow(
          "Print Signature Line on Receipt",
          tableSide?.tableSidePrintSignatureLine
        )}
        {renderTableRow(
          "Show Total Price on Receipt",
          tableSide?.enableTotalTicketPrice
        )}
        {renderTableRow(
          "Use OrderPrinter app",
          tableSide?.tablesideTicketUseOrderPrinter
        )}
      </div>
    </>
  );
}
