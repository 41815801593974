import { Button, Grid, TextField, Link } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  useGetDietitianDocumentsQuery,
  useUploadDietitianDocumentMutation,
} from "../../../../../../../store/apis/DietitianAPis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import DeleteButton from "../../../../../../shared/DeleteButton";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";

export default function DocumentManagement({
  currentDietitian,
  isAuthenticated,
}) {
  const [disableCDR, setDisableCDR] = useState(true);
  const [disableLiability, setDisableLiability] = useState(true);
  const [disableCSA, setDisableCSA] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [areFieldsActive, setAreFieldsActive] = useState(false);
  const [fileError, setFileError] = useState(false);

  const {
    data: dietitianDocuments,
    isFetching: loadingDocuments,
    isError: errorDocuments,
  } = useGetDietitianDocumentsQuery(currentDietitian?.id, {
    skip: !currentDietitian?.id,
  });

  const [uploadDocumentAPI, { isSubmitting, isSuccess, data: uploadResult }] =
    useUploadDietitianDocumentMutation();

  useEffect(() => {
    if (areFieldsActive) {
      if (!description || description === "") {
        setDescriptionError(true);
      } else setDescriptionError(false);
      if (!uploadedFile) {
        setFileError(true);
      } else setFileError(false);
    }
  }, [description, uploadedFile, areFieldsActive]);

  useEffect(() => {
    if (isSuccess && !isSubmitting && uploadResult) {
      setAreFieldsActive(() => false);
      setUploadedFile(null);
      setFileName("");
      setDescription("");

      if (uploadResult?.documentTypeId === 1) {
        setDisableCDR(true);
      } else if (uploadResult?.documentTypeId === 2) {
        setDisableLiability(true);
      } else if (uploadResult?.documentTypeId === 3) {
        setDisableCSA(true);
      }
    }
  }, [isSuccess, isSubmitting]);

  function uploadDocument(type) {
    if (!description || description === "") {
      setDescriptionError(true);
      setAreFieldsActive(true);
      return;
    } else setDescriptionError(false);
    if (!uploadedFile) {
      setFileError(true);
      setAreFieldsActive(true);
      return;
    } else setFileError(false);

    const data = new FormData();
    data.append("file", uploadedFile);
    data.append("userId", currentDietitian?.dietitianId);
    data.append("description", description);
    if (type) {
      data.append("documentTypeId", type);
    }

    uploadDocumentAPI(data);
  }

  useEffect(() => {
    if (isAuthenticated && currentDietitian?.id !== 0) {
      setDisableCDR(
        !(
          currentDietitian?.cdrdocumentId === null ||
          currentDietitian?.cdrdocumentId === 0
        )
      );
      setDisableLiability(
        !(
          currentDietitian?.liabilityInsuranceDocumentId === null ||
          currentDietitian?.liabilityInsuranceDocumentId === 0
        )
      );
      setDisableCSA(
        !(
          currentDietitian?.csadocumentId === null ||
          currentDietitian?.csadocumentId === 0
        )
      );
    }
  }, [isAuthenticated, currentDietitian]);

  const documentsColumn = [
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.documentTypeId === 1
              ? "(CDR) "
              : params.row.documentTypeId === 2
              ? "(Liab) "
              : params.row.documentTypeId === 3
              ? "(CSA) "
              : ""}
            {params.row.description}
          </>
        );
      },
    },
    {
      field: "originalFileName",
      headerName: "Document",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            target="_blank"
            rel="noreferrer"
            download={params.row.originalFileName}
            href={`${params.row.url}`}>
            {params.row.originalFileName}
          </Link>
        );
      },
    },
    {
      field: "dateUploaded",
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
      headerName: "Date Uploaded",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 0.5,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}>
            <DeleteButton
              index={params.row.id}
              entityName={"Consultant Document"}
              apiPath="deleteDietitianDocument"
            />
          </Box>
        );
      },
    },
  ];

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
    const { name } = file;
    setFileName(name);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            component="label"
            variant="outlined"
            color={fileError ? "error" : "primary"}
            startIcon={
              <span className="material-icons">
                {uploadedFile ? "description" : "border_clear"}
              </span>
            }
            sx={{ marginRight: "1rem" }}>
            {uploadedFile ? `Selected File: ` : `Select File`}
            <input
              type="file"
              accept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .png, .jpg, .jpeg"
              hidden
              onChange={handleFileUpload}
            />
            {fileName}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => setDescription(e.target.value)}
            label="Description"
            value={description}
            fullWidth
            error={descriptionError}></TextField>
        </Grid>
        <Grid item xs={12}>
          <BaseSubmitButton
            fullWidth
            text={"Upload"}
            isSubmitting={isSubmitting}
            sx={{ marginBottom: "8px" }}
            onClick={() => uploadDocument()}
            startIcon={<span className="material-icons">upload_file</span>}
          />
        </Grid>
        <Grid item xs={4}>
          <BaseSubmitButton
            fullWidth
            text={"Upload CDR"}
            isSubmitting={isSubmitting}
            onClick={() => uploadDocument(1)}
            startIcon={<span className="material-icons">upload_file</span>}
            disabled={disableCDR}
          />
        </Grid>
        <Grid item xs={4}>
          <BaseSubmitButton
            fullWidth
            text={"Upload Liability"}
            isSubmitting={isSubmitting}
            onClick={() => uploadDocument(2)}
            startIcon={<span className="material-icons">upload_file</span>}
            disabled={disableLiability}
          />
        </Grid>
        <Grid item xs={4}>
          <BaseSubmitButton
            fullWidth
            text={"Upload CSA"}
            isSubmitting={isSubmitting}
            onClick={() => uploadDocument(3)}
            startIcon={<span className="material-icons">upload_file</span>}
            disabled={disableCSA}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseDataGrid
            autoHeight={false}
            width={"99%"}
            rows={dietitianDocuments}
            loading={loadingDocuments}
            columns={documentsColumn}
            error={errorDocuments}
          />
        </Grid>
      </Grid>
    </>
  );
}
