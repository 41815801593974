import GeneratedForm, {
  FORM_TYPES,
} from "components/shared/generatedForm/GeneratedForm";
import React from "react";

export default function Field({ field }) {
  return (
    <GeneratedForm
      list={[
        {
          props: {
            name: `field-${field?.id}`,
            label: field?.label,
          },
          type: field?.type ? FORM_TYPES[field?.type] : FORM_TYPES.text,
          size: 12,
          options: field?.options,
        },
      ]}
    />
  );
}
