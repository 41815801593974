import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Slide,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import React, { useRef, useState } from "react";

export function AdminCard({ cardProperties }) {
  const containerRef = useRef(null);
  const [checked, setChecked] = useState(false);

  return (
    <Grid item xs={12} md={4}>
      <Card
        ref={containerRef}
        className={cardProperties.IsActive ? "adminCard active" : "adminCard"}
        onMouseEnter={() => setChecked(true)}
        onMouseLeave={() => setChecked(false)}
        sx={{ maxWidth: 345 }}>
        <Slide
          in={checked}
          unmountOnExit
          direction="down"
          container={containerRef.current}>
          <CardActions className="cardActionsRoot">
            {cardProperties.Children.map((data, index) => {
              return (
                <Grid
                  key={index}
                  component={NavLink}
                  to={data.Link}
                  item
                  style={{ textDecoration: "none" }}
                  xs
                  className={"cardActions"}>
                  {data.Name}
                </Grid>
              );
            })}
          </CardActions>
        </Slide>

        <CardContent className="adminCardContent">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="adminCardTitle">
            <cardProperties.Icon
              style={{ fontSize: "36px", marginRight: "8px" }}
            />
            {cardProperties.Name}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
