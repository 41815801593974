import React, { useEffect } from "react";
import { Box, Button, CircularProgress, Popover } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
// import BaseInput from "../../../../shared/baseForm/BaseInput";
import { DMStyles } from "../../../../../styles/Styles";
import { useVisitPlansMutation } from "../../../../../store/apis/ConsultantApis";
import { useSnackbar } from "notistack";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";

export const ButtonWithCard = ({ id, dietitianId, facilityId, visitId }) => {
  // states
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  // useForm Hook
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructed data from method
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  // veriable
  const date = watch("date");
  // used to open pop and close pop Up
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Mutation
  // mutation to create Visit Plane on the date
  const [visitPlane, { isLoading, isSuccess }] = useVisitPlansMutation();

  // function to create Visit Plane
  const addPlannedVisit = () => {
    const newEvent = {
      dietitianId: dietitianId, // You should generate a unique ID for the new event
      facilityId: facilityId,
      visitId: visitId,
      planDate: dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : null,
      scheduled: true,
      isCdmvisit: false,
      dietitian: null,
      facility: null,
      visit: null,
    };
    visitPlane(newEvent);
  };
  // UseEffect Section
  useEffect(() => {
    setValue("date", dayjs(new Date()).format("YYYY-MM-DD"));
  }, []);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const previousYear = currentYear - 1;
  const nextYear = currentYear + 1;

  // will shoe snackbar for create visit Plane
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Visit Plan is Added successfully on ${dayjs(date).format(
          "MM-DD-YYYY"
        )}`,
        {
          variant: "success",
        }
      );
    }
  }, [isSuccess]);
  return (
    <>
      <Button
        color={"warning"}
        variant="contained"
        sx={{
          padding: "5px",
          borderRadius: "10px",
        }}
        onClick={handleClick}>
        <span
          className="material-icons"
          style={{
            color: DMStyles.White,
            fontSize: "30px",
            fontWeight: "bold",
          }}>
          add
        </span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: "5px",
            border: `1px solid ${DMStyles.OrangeColor}`,
            borderRadius: "5px",
            marginTop: "5px",
          },
        }}>
        <Box
          sx={{
            width: "90%",
            marginY: "10px",
          }}>
          <BaseDatePicker
            control={control}
            label={"Planned Date for Visit"}
            name={"date"}
            errors={errors}
            size="small"
            minDate={dayjs(
              new Date(previousYear + "-01" + "-01").toISOString().split("T")[0]
            )}
            maxDate={dayjs(
              new Date(nextYear + "-12" + "-31").toISOString().split("T")[0]
            )}
            // validationProps={{
            //   min: {
            //     value: watch("date")
            //       ? new Date(previousYear, 0, 1).toISOString().split("T")[0]
            //       : null,
            //     message:
            //       "Please enter a date within the current year or the previous year.",
            //   },
            //   max: {
            //     value: watch("date")
            //       ? new Date(currentYear, 11, 31).toISOString().split("T")[0]
            //       : null,
            //     message:
            //       "Please enter a date within the current year or the previous year.",
            //   },
            // }}
          />
        </Box>
        <Button
          onClick={addPlannedVisit}
          startIcon={
            isLoading ? <CircularProgress color="white" size={18} /> : null
          }
          color={"primary"}
          variant="contained"
          sx={{
            width: "25%",
            fontSize: "13px",
            padding: "5px",
            borderRadius: "10px",
          }}>
          Add
        </Button>
      </Popover>
    </>
  );
};
