import React, { useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useGetAdaptiveEquipmentsQuery } from "../../../../../store/apis/AdaptiveEquipmentApi";
import { Button } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function AdaptiveEquipment() {
  const SEARCH_PARAM = "search";
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [searchValueDebounced, setSearchValueDebounced] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: adaptiveequipmentsPagedData,
    isError: hasError,
    isLoading: loading,
  } = useGetAdaptiveEquipmentsQuery({
    searchTerm: searchValueDebounced || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const adaptiveequipments = adaptiveequipmentsPagedData?.items || [];

  const isError = hasError;
  const isLoading = loading;

  const AdaptiveEquipmentColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 600,
    },
    {
      field: "facilitySpecific",
      headerName: "Community Specific",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,

      renderCell: (params) => {
        return (
          <YesNoDisplay value={params?.row?.facilitySpecific} center={true} />
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={adaptiveequipments}
            hasEdit={true}
            editProps={{
              To: `addAdaptiveEquipment?id=${params.row.id}`,
              title: "Adaptive Equipment",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["name"],
              apiPath: "deleteAdaptiveEquipment",
              title: "Adaptive Equipment",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        mb={1}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setSearchValueDebounced}
          setSearchParams={setSearchParams}
        />
        <Button
          component={Link}
          to={`AddAdaptiveEquipment`}
          color={"primary"}
          variant="contained"
          endIcon={<PostAdd />}>
          Add Adaptive Equipment
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={adaptiveequipments}
        rowCount={adaptiveequipmentsPagedData?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        columns={AdaptiveEquipmentColumns}
        error={isError}
        autoHeight={false}
      />
    </>
  );
}
