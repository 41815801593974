import { Box, CircularProgress, MenuItem, Select } from "@mui/material";
import React from "react";

export const PrintReportButton = ({
  reportsList,
  feature,
  onChange,
  loading,
}) => {
  const handleMenuItemClick = (value) => {
    // Manually create the event object
    const event = {
      target: { value },
    };
    onChange(event);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
          borderRadius: "5px",
          backgroundColor: "white",
          color: "white",
          height: "33px",
          lineHeight: "19px !important",
          fontWeight: "bold",
          outline: "none",
          border: "1px solid black",
        }}>
        <Select
          id="feature-select"
          value={feature}
          // className="baseButton"
          style={{
            width: loading ? "80%" : "100%",
            color: "black",
            fontWeight: "800",
            outline: "none",
            border: "none !important",
          }}
          sx={{
            // marginTop: "10px",

            ".MuiSvgIcon-root ": {
              fill: "black !important",
            },
            ".MuiOutlinedInput-notchedOutline": { border: "0px" },
          }}
          size="small"
          displayEmpty>
          {!feature && (
            <MenuItem value="" disabled>
              Reports
            </MenuItem>
          )}

          {/* <MenuItem value="">Reports</MenuItem> */}
          {reportsList?.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              onClick={() => handleMenuItemClick(option.id)}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {loading ? <CircularProgress size={20} color="black" /> : null}
      </Box>
    </>
  );
};
