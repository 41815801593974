import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseCheckBox.md)
 */
export default function BaseCheckBox({
  errors,
  control,
  validationProps,
  size,
  fontSize,
  defaultValue,
  ...rest
}) {
  const handleKeyDown = (event, onChange, checked) => {
    if (event.key === " ") {
      event.preventDefault();
      onChange(!checked);
    }
  };

  const renderCheckbox = (checked, onChange) => (
    <Checkbox
      error={errors ? errors[rest?.name] : null}
      checked={checked}
      onChange={(event) => {
        onChange(event.target.checked);
      }}
      onKeyDown={(event) => handleKeyDown(event, onChange, checked)}
      size={size || "medium"}
      sx={{
        padding: 0,
        paddingLeft: "9px",
        "& .MuiSvgIcon-root": { fontSize: fontSize },
      }}
      inputProps={{
        "aria-label": rest?.label,
      }}
      {...rest}
    />
  );

  const renderLabel = () => (
    <Typography sx={{ fontSize: fontSize }}>{rest?.label}</Typography>
  );

  return (
    <FormControl fullWidth>
      {control ? (
        <Controller
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={renderCheckbox(value, onChange)}
              label={renderLabel()}
              sx={{ fontSize: "10px" }}
            />
          )}
          control={control}
          name={rest?.name || rest?.id}
          defaultValue={defaultValue || false}
          rules={validationProps}
        />
      ) : (
        <FormControlLabel
          control={renderCheckbox(rest?.checked, rest?.onChange)}
          label={rest?.label}
        />
      )}
    </FormControl>
  );
}
