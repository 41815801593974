import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetFacilityCareTypeQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "components/shared/EditButton";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function CommunityCareType() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const {
    data: careTypes,
    isError: hasError,
    isLoading: loading,
  } = useGetFacilityCareTypeQuery(id, { skip: !id });

  const careTypeColumns = [
    {
      field: "careTypeName",
      headerName: "Care Type",
      flex: 1,
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"end"} my="8px">
        <EditButton
          To={`edit?id=${id}`}
          data={careTypes}
          // styles={{ bg: "#575757", color: "white" }}
        />
      </FlexBox>

      <BaseDataGrid
        rows={careTypes}
        columns={careTypeColumns}
        loading={loading}
        hasError={hasError}
        autoHeight={true}
      />
    </>
  );
}
